<template>
  <div class="my-6 recent-items-wrapper" v-if="advertsData.length > 0">
    <div class="block-title mb-1">
      <div class="is-size-5">{{ $t("Your Recently Viewed Items") }}</div>
      <div>
        <router-link :to="'/recent'">
          {{ $t("View All") }} <b-icon icon="angle-right"></b-icon>
        </router-link>
      </div>
    </div>
    <div class="box recent-gall-carusel">
      <Swiper
        :slides-per-view="5"
        :breakpoints="breakpoints"
        :space-between="10"
        :loop="true"
        :navigation="true"
        ref="swiper"
        v-if="advertsData.length > 0"
        :modules="modules"
      >
        <SwiperSlide
          v-for="(item, i) in advertsData"
          :key="'slide-' + i"
        >
          <router-link :to="'/advert/' + item.id" class="card-link">
            <img
              v-if="item.ad_images.images.length > 0"
              class="image-item no-drag"
              :src="showImage(item.ad_images.images[0].image)"
              @error="imageError"
            />
            <img
              v-else
              class="image-item no-drag"
              src="https://salex.md/img/noimage.png"
            />
          </router-link>
          <div class="recent-price" v-if="item.ad_price != 0">
            {{ item.ad_price + " " + showCurrency(item.ad_currency) }}
          </div>
        </SwiperSlide>
      </Swiper>

      <!-- If you prefer to use custom navigation buttons, uncomment this block and adjust accordingly -->
      <!--
      <div class="control-buttons">
        <button class="control-back">
          <b-icon icon="angle-left" />
        </button>
        <button class="control-next">
          <b-icon icon="angle-right" />
        </button>
      </div>
      -->
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';

import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';

export default {
  name: "RecentAdvertCarousel",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Navigation],
      advertsIds: [],
      advertsData: [],
      currentItem: 1,
      gallery: false,
      breakpoints: {
        100: {
          slidesPerView: 1,
          spaceBetween: 5,
        },
        480: {
          slidesPerView: 1,
          spaceBetween: 5,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 10,
        },
      },
    };
  },
  mounted() {
    if (localStorage.getItem("sxRecent") !== null) {
      this.advertsIds = localStorage.getItem("sxRecent").split(",").reverse();
      if (this.advertsIds.length > 0) {
        this.getAdverts();
      }
    }
  },
  computed: {
    countItems() {
      return this.advertsData.length;
    },
  },
  methods: {
    getAdverts() {
      console.log("Loading recent adverts");
      this.isLoading = true;
      this.axios
        .post(`${this.$env.api}/adverts/get_adverts.php`, {
          idsList: this.advertsIds.join(","),
          itemsNum: 10,
          action: "listHistory",
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            this.advertsData = response.data.items;
            this.isEmpty = false;
            console.log("Total Result: " + this.advertsData.length);
          } else {
            this.hasItems = false;
            console.log("Error getting data");
            this.advertsData = [];
          }
        })
        .catch((error) => {
          this.hasItems = false;
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    showImage(image) {
      if (image) {
        let imageOut = image.replace(/\.[^/.]+$/, "") + "_h400.webp";
        return this.$env.imagesURL + "gallery/400/" + imageOut;
      } else {
        return "https://salex.md/img/noimage.png";
      }
    },
    showCurrency(currency) {
      if (!currency) return;
      let currencies = {
        MDL: "Lei",
        EUR: "€",
        USD: "$",
      };
      return currencies[currency];
    },
    imageError(event) {
      event.target.src = "https://salex.md/img/noimage.png";
    },
  },
};
</script>


<style scoped>
.recent-gall-carusel {
  overflow: hidden;
  position: relative;
  user-select: none;
}

.swiper-slide {
  height: 200px;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

.image-item {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.recent-price {
  position: absolute;
  bottom: 0;
  background-color: #ffffff;
  padding: 5px 10px 0px 10px;
  border-radius: 5px 5px 0 0;
  font-size: 1rem;
  font-weight: 700;
  color: #000000;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, 0%);
}

.block-title {
  display: flex;
  justify-content: space-between;
}

.recent-items-wrapper {
  position: relative;
  z-index: 1;
}

/* Mobile */
@media screen and (max-width: 768px) {
  .recent-price {
    font-size: 0.8rem;
  }
}
</style>
