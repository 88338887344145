<template>
  <section>
    <b-modal :active.sync="isActive" has-modal-card trap-focus aria-role="dialog" aria-modal :can-cancel="false">

      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('Exchange Rates') }}</p>
          <button type="button" class="delete" @click="closeModal()" />
        </header>
        <section class="modal-card-body modal-card-nofoot">
          <b-field horizontal label="USD">
              <b-input @input="updateCurrency('usd', $event)" v-model="form.usd"></b-input>
              <p class="control">
                <span><img class="cur-flag" width="30px" src="@/static/flags/us.svg"></span>
            </p>
            </b-field>

            <b-field horizontal label="EUR">
              <b-input @input="updateCurrency('eur', $event)" v-model="form.eur"></b-input>
              <p class="control">
                <span><img class="cur-flag" width="30px" src="@/static/flags/eu.svg"></span>
            </p>
            </b-field>

            <b-field horizontal label="MDL">
              <b-input @input="updateCurrency('mdl', $event)" v-model="form.mdl"></b-input>
              <p class="control">
                <span><img class="cur-flag" width="30px" src="@/static/flags/md.svg"></span>
            </p>
            </b-field>

            <b-field horizontal label="RON">
              <b-input @input="updateCurrency('ron', $event)" v-model="form.ron"></b-input>
              <p class="control">
                <span><img class="cur-flag" width="30px" src="@/static/flags/ro.svg"></span>
            </p>
            </b-field>

            <b-field horizontal label="UAH">

              <b-input @input="updateCurrency('uah', $event)" v-model="form.uah"></b-input>
              <p class="control">
                <span><img class="cur-flag" width="30px" src="@/static/flags/ua.svg"></span>
            </p>
            </b-field>

            <b-field horizontal label="RUB">
              <b-input @input="updateCurrency('rub', $event)" v-model="form.rub"></b-input>
              <p class="control">
                <span><img class="cur-flag" width="30px" src="@/static/flags/ru.svg"></span>
            </p>
            </b-field>

            <b-field horizontal label="GBP">
              <b-input @input="updateCurrency('gbp', $event)" v-model="form.gbp"></b-input>
              <p class="control">
                <span><img class="cur-flag" width="30px" src="@/static/flags/gb.svg"></span>
              </p>
            </b-field>

        </section>
      </div>
    </b-modal>
  </section>
</template>

<script>

export default {
  name: "ExchangeModal",
  data() {
    return {
      isLoading: false,
      rateValues: [],
      rateDate: "",
      form: {
        usd: 0,
        eur: 0,
        mdl: 0,
        ron: 0,
        uah: 0,
        rub: 0,
        gbp: 0,
      }
    };
  },
  methods: {
    call(phone){
      window.open('tel:'+phone);
    },
    closeModal(){
      this.$store.commit("modals/hideModal", "exchange");
    },
    getRates(){
      this.isLoading = true;
      //axios post
      this.axios
        .post("/functions/rates.php",
          {
            action: "getRates",
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.rateDate = response.data.Date;
          this.rateValues = response.data.Valutes;
          console.log("gotRates");
          // console.log(response);
          // console.table(this.rateValues);
          this.form.eur = 100;
          this.recalculateCurrencies('eur');
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    updateCurrency(currency, event) {
      this.form[currency] = event;
      this.recalculateCurrencies(currency);
    },
    resetAllCurrencies() {
      this.form.usd = 0;
      this.form.eur = 0;
      this.form.mdl = 0;
      this.form.ron = 0;
      this.form.uah = 0;
      this.form.rub = 0;
      this.form.gbp = 0;
    },
    recalculateCurrencies(baseCurrency) {
      console.log('recalculateCurrencies', baseCurrency);
      // if (!baseCurrency || this.form[baseCurrency] === 0) {
      //   this.resetAllCurrencies();
      //   return;
      // }

      if (baseCurrency !== 'mdl') {
      // Find the rate for the base currency
      const baseRate = this.rateValues.find(rate => rate.CharCode.toLowerCase() === baseCurrency);

      if(!baseRate) {
        console.error('Could not find rate for base currency', baseCurrency);
        return;
      }

      // Calculate the MDL value based on the base currency
      
        this.form.mdl = this.form[baseCurrency] * parseFloat(baseRate.Value);
        this.form.mdl = parseFloat(this.form.mdl.toFixed(4));
      }

      // Update all other currencies based on the MDL value
      this.rateValues.forEach((rate) => {
        const charCode = rate.CharCode.toLowerCase();
        if (charCode !== baseCurrency) {
          this.form[charCode] = parseFloat((this.form.mdl / parseFloat(rate.Value)).toFixed(4));
        }
      });
    },
  },
  computed: {
    isActive: {
      get: function () {
        return this.$store.state.modals.exchange;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "exchange");
        return false;
      }
    }
  },
  watch: {
    currForm: {
      handler(newForm, oldForm) {
        //console.log('currForm changed', newForm, oldForm);
        // Determine which currency was changed
        const changedCurrency = Object.keys(newForm).find(key => newForm[key] !== oldForm[key]);
        
        // Recalculate based on the changed currency
        this.recalculateCurrencies(changedCurrency);
      },
      deep: true // Watch object deeply
    },
    //watcher for isActive
    isActive: function (val) {
      if(val){
        this.getRates();
      }
    }
  },
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between;
  /* flex-end; */
}

.cur-flag{
  border-radius: 3px;
    margin-top: 10px;
}
</style>
