import axios from "axios";
let apiUrl = process.env.VUE_APP_API_URL;

export default{
  namespaced: true,
  state: {
    favs: [],
  },
  getters: {
  },
  mutations: {
    setFavorites(state, payload) {
      console.log("setting Favs:", payload);
      state.favs = [...payload];
    },
  },
  actions: {
    async loadFavorites({commit}) {
      console.log("sxToken", localStorage.sxToken);
      console.log("loadFavorites");
      try {
        const response = await axios
        .post(`${apiUrl}/favorites/manage_favorites.php`, {
          token: localStorage.sxToken,
          action: "getFavs",
        });

        if(response.data.success){
          if(response.data.items.length > 0){
            commit("setFavorites", response.data.items);
          }else{
            commit("setFavorites", []);
          }
        }
      } catch (error) {
        console.log(error);
        commit("setFavorites", []);
      }
    },

  },
  modules: {
  }
}
