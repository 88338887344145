<template>
  <div
    class="search-box"
    @keydown.down.prevent="focusOnNextDiv"
    @keydown.up.prevent="focusOnPreviousDiv"
    @keydown.enter.prevent="detectFocused"
  >
    <div class="search-box-wrap">
      <b-field>

        <!-- <b-autocomplete
          v-model="form.location.data"
          :placeholder="$t('Location')"
          open-on-focus
          group-field="type"
          group-options="items"
          :data="filteredDataObj"
          @select="(option) => (selected = option)"
          icon="caret-down"
          class="autocomplete20"
          v-if="!isMobile"
        >
        </b-autocomplete> -->

        <b-input
          :placeholder="$t('Search') + '...'"
          type="search"
          expanded
          v-model="form.search.data"
          @input="preSearch()"
          ref="inputField"
          @focus="selectedItemID = null"
        >
        </b-input>
        <p class="control">
          <b-button
            @click="doSearch"
            type="is-primary"
            icon-left="magnifying-glass"
            ></b-button
          >
        </p>
      </b-field>

    </div>

    <div
      class="pre-result"
      v-if="preSubcategories.length > 0"
      v-click-outside="hidePreResults"
    >
      <fieldset>
        <template v-for="(item, index) in preSubcategories">
          <div
            @click="goToPage(item)"
            class="pre-result-item focus-div"
            :ref="`focusDiv${index}`"
            tabindex="0"
            @focus="highlightDiv(index, item)"
            @blur="unhighlightDiv(index)"
          >
            <div class="pre-result-title">{{ form.search.data }}</div>
            <div class="pre-result-category">
              {{ getSubcategoryName(item) }}
            </div>
          </div>
        </template>
      </fieldset>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";

export default {
  name: "Search",
  props: {
    attributesData: {
      type: Array,
      default: ()=>{return []},
    },
    subID: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentDivIndex: -1,
      selectedItemID: null,
      type: "Sell",
      location: "Chisinau",
      //attributesData: [],
      selectedOptionsShow: [],
      selectedOptions: [],
      showSearchOptions: false,
      preSearchResults: [],
      preSubcategories: [],
      form: {
        search: {
          data: "",
          status: null,
          message: null,
        },
        location: {
          data: "",
          status: null,
          message: null,
        },
      },
    };
  },
  mounted() {
    console.log(this.subID);
    // if (this.subID) {
    //   this.loadAttributes();
    // }
    // if (this.$route.query.search) {
    //   this.form.search.data = this.$route.query.search;
    // }
    if (this.$store.state.searchTerm) {
      this.form.search.data = this.$store.state.searchTerm;
      // this.$store.commit("setSearchTerm", null);
    } else {
      this.form.search.data = "";
    }
    if (this.$route.query.q) {
      this.form.search.data = this.$route.query.q;
    }
    if (this.$route.query.l) {
      this.form.location.data = this.$route.query.l;
    }
    // if (this.$route.query.priceBig) {
    //   this.form.priceBig.data = this.$route.query.priceBig;
    // }
    // if (this.$route.query.priceSmall) {
    //   this.form.priceSmall.data = this.$route.query.priceSmall;
    // }
    // if (this.$route.query.currency) {
    //   this.form.currency.data = this.$route.query.currency;
    // }
  },
  computed: {
    ...mapState(["isMobile", "locations", "catrgoriesFlat", "searchTerm"]),
    ...mapGetters(["getSubcategories"]),
    filteredDataObj() {
      if (!this.locations) return [];

      const normalizeString = (str) => {
        return str
          .replace(/ă|Ă/g, "a")
          .replace(/ș|Ș/g, "s")
          .replace(/ț|Ț/g, "t")
          .replace(/î|Î/g, "i")
          .toLowerCase();
      };

      const query = normalizeString(this.form.location.data);

      const newData = [];
      this.locations.forEach((element) => {
        // First, filter items that start with the query
        let items = element.items.filter((item) => {
          return normalizeString(item).startsWith(query);
        });

        // If no items found, then filter items that contain the query anywhere
        // if (items.length === 0) {
        //   items = element.items.filter((item) => {
        //     return normalizeString(item).indexOf(query) >= 0;
        //   });
        // }

        if (items.length) {
          newData.push({ items });
        }
      });

      return newData;
    }
  },
  methods: {
    detectFocused(event) {
      //console.log(event);
      //this.currentDivIndex = event.target.dataset.index;
      //console.log(this.currentDivIndex);
      if (this.selectedItemID) {
        //console.log("go to id:", this.selectedItemID);
        this.goToPage(this.selectedItemID);
      } else {
        //console.log('do search all');
        this.doSearch();
      }
    },
    focusOnNextDiv(event) {
      //console.log(event);
      event.stopPropagation();
      if (this.currentDivIndex < this.preSubcategories.length - 1) {
        this.currentDivIndex++;
        this.$refs[`focusDiv${this.currentDivIndex}`][0].focus();
      }
    },
    focusOnPreviousDiv(event) {
      //console.log(event);
      event.stopPropagation();
      if (this.currentDivIndex > 0) {
        this.currentDivIndex--;
        this.$refs[`focusDiv${this.currentDivIndex}`][0].focus();
      }
    },
    highlightDiv(index, item) {
      this.selectedItemID = item;
      this.$refs[`focusDiv${index}`][0].classList.add("highlight");
    },
    unhighlightDiv(index) {
      this.$refs[`focusDiv${index}`][0].classList.remove("highlight");
    },




    doSearch() {
      console.log("do the search");
      console.log(this.form);
      console.log(this.selectedOptions);
      let data = {
        token: localStorage.sxToken,
        action: "search",
        type: this.type,
        location: this.location,
        attributes: this.selectedOptions,
        form: this.form,
      };
      console.log(data);

      this.$router.push({
        path: "/search",
        query: { q: this.form.search.data, l: this.form.location.data },
      });
      //this.$emit("search", data);
    },
    preSearch() {
      console.log("do the pre search");
      this.$store.commit("setSearchTerm", "");
      if (this.form.search.data.length < 3) {
        this.preSearchResult = [];
        this.preSubcategories = [];
        return;
      }
      this.isLoading = true;
      this.axios
        .post(`${this.$env.api}/adverts/get_adverts.php`, {
          search: this.form.search.data,
          action: "preSearch",
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            this.preSearchResult = response.data.items;
            const subcatSet = new Set(response.data.subcats);
            this.preSubcategories = [...subcatSet];
            console.log(this.preSubcategories);
            //
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    getSubcategoryName(id) {
      //console.log("id",id);
      //return id;
      let subcategories = this.getSubcategories;
      let index = subcategories.findIndex((item) => item.id == id);
      if (index == -1) return "";
      return subcategories[index].labelEN;
    },
    goToPage(id) {
      console.log("go to page", id);
      this.preSubcategories = [];
      this.$store.commit("setSearchTerm", this.form.search.data);
      this.$router.push(this.formatURL(id));
    },
    formatURL(id) {
      let catSlug = "";
      let subSlug = "";
      this.catrgoriesFlat.forEach((category) => {
        let index = category.subcategories.findIndex((item) => item.id == id);
        if (index !== -1) {
          catSlug = category.slug;
          subSlug = category.subcategories[index].slug;
        }
      });
      return `/category/${catSlug}/${subSlug}`;
    },
    hidePreResults() {
      this.preSearchResult = [];
      this.preSubcategories = [];
    },
  },
  watch: {
    subID: function (newVal, oldVal) {
      console.log("subID changed", newVal, oldVal);
      if (newVal !== null) {
        //this.loadAttributes();
        this.selectedOptionsShow = [];
        this.selectedOptions = [];
      } else {
        //this.attributesData = [];
        this.showSearchOptions = false;
      }
    },
    // "searchTerm": function (newVal, oldVal) {
    //   console.log("searchTerm changed", newVal, oldVal);
    //   if (newVal !== null) {
    //     this.form.search.data = newVal;
    //   } else {
    //     this.form.search.data = '';
    //   }
    // },
  },
};
</script>

<style scoped>
/* .search-box {
  position: relative;
  margin-bottom: 2rem;
  background-color: hsl(0deg, 0%, 100%);
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: hsl(0deg, 0%, 29%);
  display: block;
  transition: all 0.3s ease;
} */

/* .search-box-wrap {
  padding: 1.25rem 1.25rem;
} */
.search-options-bt {
  cursor: pointer;
}

.search-options {
  padding-top: 25px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  /* transform: translateY(10px); */
  opacity: 0;
}

.options-bt-wrapper {
  /* position: absolute; */
  bottom: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.control-open {
  all: unset;
  display: flex;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: none;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  justify-content: center;
  align-content: center;
  z-index: 1;
  border-radius: 20px 20px 0 0;
  box-shadow: inset 0px 4px 6px 0px rgb(193 193 193 / 40%);
}
.control-open:hover {
  background-color: #f1f1f1;
  color: rgb(0, 0, 0);
}
.pre-result {
  background-color: #ffffff;
  padding: 5px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 4px 6px 0px rgb(193 193 193 / 40%);
  margin-bottom: 2rem;
  width: 100%;
  position: absolute;
  z-index: 10;
  /* top: 80px; */
}
.pre-result-item {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 10px;
}

.pre-result-item:hover {
  background-color: #eeeeee;
}
/* .pre-result-item:not(:last-child) {
  border-bottom: #a1a1a1;
} */

.pre-result-title {
  font-size: 1rem;
  font-weight: 600;
}

.pre-result-category {
  font-size: 0.8rem;
  font-weight: 400;
  color: #a1a1a1;
}

.autocomplete20 {
  width: 20%;
}

.highlight {
  background-color: #eeeeee;
  /* border: 1px solid #a1a1a1; */
  outline: none;
}

.filter-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
</style>
