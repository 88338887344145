<template>
  <div class="scroll-item" ref="scrollContainer">
    <div class="sx-chat">
      <template v-for="(message, index) in messages">
        <div class="message-wrapper"
          @mouseover="showDate = index"
          @mouseleave="showDate = -1"
          :class="
            isMine(message.sender_id) ? 'sx-sent' : 'sx-received'
          "
        >
        <div v-if="showDate==index" class="message-date">{{formatDateTime(message.created_at, lang)}}</div>
        <div class="message-text">{{ message.message }}</div>
        </div>
      </template>
      <div class="is-typing" v-if="isLoading">
        <div class="is-typing__dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
export default {
  name: "FloatingChatMessages",
  props: {
    messages: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [formatMixin],
  data() {
    return {
      showDate: -1,
    };
  },
  computed: {
    ...mapState(["lang"]),
    ...mapState({
      user: (state) => state.users.user,
    }),
  },
  // updated() {
  //   this.scrollToBottom();
  // },
  methods: {
    isMine(id) {
      if(id==false) return false;
      if(id==true) return true;
      console.log("isMine", id);
      console.log("isMine user", this.user);
      
      return this.user.id == id;
    },
    scrollToBottom() {
      this.$nextTick(() => {
        if(this.showDate==-1){
          this.$refs.scrollContainer.scrollTop = this.$refs.scrollContainer.scrollHeight;
        }
      });
    },
  },
  watch: {
    //deep watch
    messages: {
      handler: function (val, oldVal) {
        this.scrollToBottom();
      },
      deep: true,
    },

  },
};
</script>

<style scoped>
.sx-chat {
  /* border: 1px solid #e6e6e6;
    border-radius: 5px; */
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.sx-sent {
  background-color: #f5f5f5;
  align-self: flex-end;
}

.sx-received {
  background-color: #cdffea;
  align-self: flex-start;
}

.scroll-item {
  overflow: auto;
  height: 350px;
  /* display: flex;
    flex-direction: column;
    justify-content: flex-end; */
}

@-moz-document url-prefix() {
  .scroll-moz {
    overflow-y: scroll;
    scrollbar-color: #979797 rgba(0, 0, 0, 0.3);
    scrollbar-width: thin;
  }
}

.scroll-item::-webkit-scrollbar {
  width: 4px;
}

.scroll-item::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scroll-item::-webkit-scrollbar-thumb {
  background: #979797;
}




.is-typing {
  display: inline-block;
  margin-left: 10px;
  font-size: 14px;
  color: #777;
}

.is-typing__dots {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 20px;
}

.is-typing__dots span {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 3px;
  border-radius: 50%;
  background-color: #777;
  animation: is-typing 1s ease-in-out infinite;
}

@keyframes is-typing {
  0% {
    opacity: 0.2;
    transform: translateY(0);
  }
  25% {
    opacity: 1;
    transform: translateY(-5px);
  }
  50% {
    opacity: 0.2;
    transform: translateY(0);
  }
}


.message-date {
  position: absolute;
  bottom: -0.6rem;
  font-size: 0.6rem;
  line-height: 1;
  font-weight: 400;
  color: hsl(0, 0%, 52%);
  background-color: hsl(0deg, 0%, 100%);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}
.sx-sent .message-date {
  border: 1px solid #e6e6e6;
  right: 0;
}
.sx-received .message-date {
  border: 1px solid #cdffea;
  left: 0;
}

.message-wrapper {
  display: inline-block;
  border-radius: 5px;
  padding: 20px 10px;
  margin-bottom: 20px;
  /* width: 50%; */
  max-width: 90%;
  min-width: 120px;
  position: relative;
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  /*width: 100%;
  height: 100%;
  background-color: hsl(0deg, 0%, 100%);
  border-radius: 6px; */
  /* box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02); */
  /* border: 1px solid #e6e6e6;
  color: hsl(0deg, 0%, 29%);
  padding: 1.25rem;
  margin-bottom: 2rem; */
}

.message-text {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: hsl(0, 0%, 43%);
}


  .sx-sent{
    width: 100%;
  }
  .sx-receive {
    width: 100%;
  }

</style>
