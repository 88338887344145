export default{
  namespaced: true,
  state: {
    //navigation modals
      sidebar: false,
      filter: false,
      catsidebar: false,
      accsidebar: false,
      mobileChat: false, //!not used
      advSaved: false, //!not used
    //general modals
      generatorProgress: false,
      gallery: false,
      contact: false,
      message: false,
      offer: false,
      review: false,
      report: false,
      exchange: false,
      sort: false,
    //advert modals
      map: false,
    //account modals  
      login: false,
      logout: false,
      register: false,
      registerPhone: false,
      restore: false,
      password: false,
      phone: false,
      email: false,
      confirm: false,
      pay: false,
      addPayment: false,
      corp: false,
    //testing
      adstest: false,
      invite: false,
  },
  getters: {
  },
  mutations: {
    showModal(state, payload) {
      console.log("showing modal: ",payload );
      state[payload] = true;
    },
    hideModal(state, payload) {
      state[payload] = false;
    },
    toggleModal(state, payload) {
      state[payload] = !state[payload];
    },
  },
  actions: {
  },
  modules: {
  }
}
