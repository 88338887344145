<template>
  <div>
    <b-menu :activable="false" v-if="!user.isLogged">
      <b-menu-list :label="$t('Account')">
        <b-menu-item
          icon="arrow-right-to-bracket"
          :label="$t('Login')"
          @click="showModal('login')"
          :active="isActive == 'my-profile'"
        ></b-menu-item>
        <b-menu-item
          icon="user-plus"
          :label="$t('Register')"
          @click="showModal('registerPhone')"
        ></b-menu-item>
      </b-menu-list>
    </b-menu>

    <b-menu :activable="false" v-if="user.isLogged">
      
      <b-menu-list >
        <b-menu-item
          icon="grid-2"
          :label="$t('Dashboard')"
          @click="goTo('my-dashboard')"
          :active="isActive == 'my-dashboard'"
          ></b-menu-item>
          <b-menu-item
          icon="user-headset"
          :label="$t('Help Desk')"
          @click="goTo('help-desk')"
          :active="isActive == 'help-desk'"
          ></b-menu-item>
        </b-menu-list>

      <b-menu-list :label="$t('Adverts')">
        <b-menu-item
          icon="file-image"
          :label="$t('Adverts')"
          @click="goTo('my-adverts')"
          :active="isActive == 'my-adverts'"
        ></b-menu-item>
        <b-menu-item
          icon="heart"
          :label="$t('Favorites')"
          @click="goTo('my-favorites')"
          :active="isActive == 'my-favorites'"
        ></b-menu-item>
      </b-menu-list>


      <!-- <b-menu-list label="Payments">
        <b-menu-item
          icon="file-invoice-dollar"
          :label="$t('Bills')"
          @click="goTo('my-bills')"
          :active="isActive == 'my-bills'"
        ></b-menu-item>
        <b-menu-item
          icon="clock-rotate-left"
          :label="$t('History')"
          @click="goTo('my-bills-history')"
          :active="isActive == 'my-bills-history'"
        ></b-menu-item>
        <b-menu-item
          icon="wallet"
          :label="$t('Payment Methods')"
          @click="goTo('my-payment-methods')"
          :active="isActive == 'my-payment-methods'"
        ></b-menu-item>
        </b-menu-list> -->


        <b-menu-list :label="$t('Messages')">
          <b-menu-item
            icon="comments"
            :label="$t('Messages')"
            @click="goTo('my-messages')"
            :active="isActive == 'my-messages'"
          >
        </b-menu-item>
        <b-menu-item
            icon="bell"
            :label="$t('Notifications')"
            @click="goTo('my-notifications')"
            :active="isActive == 'my-notifications'"
          >
        </b-menu-item>
        <b-menu-item
            icon="star"
            :label="$t('Reviews')"
            @click="goTo('my-reviews')"
            :active="isActive == 'my-reviews'"
          >
        </b-menu-item>
      </b-menu-list>

      
      <b-menu-list :label="$t('Account')">
        <b-menu-item
          icon="user"
          :label="$t('My Profile')"
          @click="goTo('my-profile')"
          :active="isActive == 'my-profile'"
        ></b-menu-item>
        <b-menu-item
          icon="lock-keyhole"
          :label="$t('Password')"
          @click="showModal('password')"
          :active="isActive == 'my-password'"
        ></b-menu-item>
        <!-- <b-menu-item
          tag="router-link"
          :to="{ path: '/test' }"
          icon="vial"
          label="Test Page"
          :active="isActive.test"
        ></b-menu-item> -->
        <b-menu-item
          icon="arrow-right-from-bracket"
          :label="$t('Logout')"
          @click="logout()"
        ></b-menu-item>
      </b-menu-list>


    </b-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";

import modalsMixin from "@/mixins/modalsMixin";

export default {
  name: "UserMenu",
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: "dashboard"
    };
  },
  mixins: [modalsMixin],
  methods: {
    showModal(modal) {
      if(this.isMobile){
        this.$emit("didSelect");
      }
      this.$store.commit("modals/showModal", modal);
    },
    logout() {
      this.$store.commit("users/logoutUser");
      this.$router.push({ path: "/" }).catch((err) => {
        console.log(err);
      });
      if(this.isMobile){
        this.$emit("didSelect");
      }
    },
    setActive(element) {
      this.isActive[element] = true;
    },
    goTo(path) {
      let curentPath = this.$route.path;
      curentPath = curentPath.replace("/", "");
      if (curentPath == path) {
        return;
      }
      this.$router.push({ path: "/"+path }).catch((err) => {
        console.log(err);
      });
      this.isActive = path;
      if(this.isMobile){
        this.$emit("didSelect");
      }
    },
    emitSelected() {
      this.$emit("didSelect");
    },
  },
  mounted() {
    //this.isActive[this.activeElement] = true;
    //console.log(this.isActive[this.activeElement]);
    //console.log(this.activeElement);
    let path = this.$route.path;
    path = path.replace("/", "");
    this.isActive = path;
  },
  computed: {
    ...mapState("users", ["user"]),
  },
};
</script>
<style scoped></style>
