<template>
  <div v-if="bannersLoaded && banners.length > 0" class="slider-wrapper">
    <div class="top-spacer"></div>
    <div class="top-carusel">
      <Swiper
        v-if="bannersLoaded"
        :slides-per-view="1"
        :loop="true"
        :autoplay="{ delay: 5000, disableOnInteraction: false }"
        :navigation="true"
        ref="swiper"
        :modules="modules"
      >
        <SwiperSlide
          v-for="(item, i) in banners"
          :key="'slide-' + i"
        >
          <div class="fade-img" @click="openPage(item.linkURL,item.linkTarget)">
            <img
              class="image-item"
              :src="showItem(item)"
            />
          </div>
        </SwiperSlide>
      </Swiper>
      <!-- <div class="control-buttons">
        <button class="control-back" @click="goBack()"><b-icon icon="angle-left"/></button>
        <button class="control-next" @click="goNext()"><b-icon icon="angle-right"/></button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';

import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css/navigation';

export default {
  name: "HomeCarusel",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Autoplay, Navigation],
      banners: [],
      bannersLoaded: false,
      currentItem: 1,
      gallery: false,
      al: {
        hasGrayscale: true,
        itemsToShow: 2,
        breakpoints: {
          768: {
            hasGrayscale: false,
            itemsToShow: 4,
          },
          960: {
            hasGrayscale: true,
            itemsToShow: 6,
          },
        },
      },
    };
  },
  mounted() {
    this.loadBanners();
  },
  computed: {
    ...mapState(["lang"]),
    countItems() {
      return this.banners.length || 0;
    },
  },
  methods: {
    selectedLang() {
      if (this.lang == "ro") {
        return 0;
      } else if (this.lang == "ru") {
        return 1;
      } else if (this.lang == "en") {
        return 2;
      } else {
        return 0;
      }
    },
    showItem(item) {
      if (item.images[this.selectedLang()]) {
        return this.$env.bannersURL + item.images[this.selectedLang()].image;
      } else {
        return "https://salex.md/img/noimage.png";
      }
    },
    loadBanners() {
      console.log("loading home Banners...");
      this.axios
        .post("/banners/get_banners.php", {
          action: "listItems",
          itemsType: 1,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            this.banners = response.data.items;
            this.bannersLoaded = true;
            console.log("BANNER IMAGES:", this.banners[0].images[1].image);
          } else {
            this.banners = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goBack() {
      this.$refs.swiper.swiper.slidePrev();
    },
    goNext() {
      this.$refs.swiper.swiper.slideNext();
    },
    openPage(url, target) {
      if (target == "") {
        target = "_blank";
      }
      if (url == "") {
        return;
      }
      window.open(url, target);
    },
  },
};
</script>

<style scoped>
.slider-wrapper {
  position: relative;
}

.fade-img {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
}

.fade-img:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 25%,
    rgb(245, 245, 245) 100%
  );
  height: 60%;
}

.top-carusel {
  /* position: absolute; */
  height: 400px;
  margin-top: -490px;
  z-index: 0;
}
.top-spacer {
  height: 445px;
}

.control-back,
.control-next {
  all: unset;
  display: flex;
  background-color: #ffffff00;
  border: none;
  padding: 15px 15px;
  font-size: 2rem;
  line-height: 1;
  cursor: pointer;
  transition: background-color 0.3s ease;
  justify-content: center;
  align-content: center;
  z-index: 1;
  transform: translateY(-50%);
  mix-blend-mode: difference;
}
.control-back {
  position: absolute;
  top: 50%;
  left: -10px;
}
.control-next {
  position: absolute;
  top: 50%;
  right: -10px;
}

.image-item {
  pointer-events: none;
}

/* Mobile Breakpoints */
@media (max-width: 768px) {
  .top-carusel {
    height: 300px;
    margin-top: -390px;
  }
  .top-spacer {
    height: 345px;
  }
}
</style>
