import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import Buefy from "buefy";
import VueMeta from "vue-meta";
import {i18n} from "./plugins/i18n";
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
Vue.use(LottieVuePlayer);

import vClickOutside from 'v-click-outside';
Vue.use(vClickOutside);

import bottomNavigationVue from "bottom-navigation-vue";
Vue.use(bottomNavigationVue);

Vue.config.productionTip = false;


// prod settings
axios.defaults.baseURL = "https://salex.md/api";

Vue.prototype.$env = {
  siteTitle: "Salex",
  siteUrl: "https://salex.md/",
  shareImg: "/img/share.jpg",
  api: "https://salex.md/api",
  imagesURL: "https://salex-images-live.s3.eu-central-1.amazonaws.com/",
  avatarsURL: "https://salex-avatar-live.s3.eu-central-1.amazonaws.com/",
  bannersURL: "https://admin.salex.md/banners/",
  settings:{
    views: false,
    prefilter: false,
    subcategories: true,
  }
};

//dev settings
// axios.defaults.baseURL = "https://salex.md/api-beta/";

// Vue.prototype.$env = {
//   siteTitle: "Salex",
//   siteUrl: "https://salex.md/",
//   shareImg: "/img/share.jpg",
//   api: "https://salex.md/api",
//   imagesURL: "https://salex-images-beta.s3.eu-central-1.amazonaws.com/",
//   avatarsURL: "https://salex-avatar-beta.s3.eu-central-1.amazonaws.com/",
//   bannersURL: "https://admin.salex.md/banners/",
// };

Vue.use(Buefy, {
  defaultIconPack: "syntho",
});
Vue.use(VueAxios, axios);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
