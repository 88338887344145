<template>
  <div v-if="hasBanner" class="content-banner my-6" @click="openPage()">
    <img :src="showImage()" width="100%" class="banner-image">
  </div> 
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "GeneralBanner",
  props: {
    bannerType: {
      type: Number,
      required: true,
      default: 2,
    },
  },
  data() {
    return {
      hasBanner: false,
      banners: [],
      selectedBanner: null,
      currentItem: 1,
    };
  },
  mounted() {
    this.loadBanners();
  },
  computed: {
    ...mapState(["lang"]),
    selectedLang() {
      if (this.lang == "ro") {
        return 0;
      } else if (this.lang == "ru") {
        return 1;
      } else if (this.lang == "en") {
        return 2;
      }
    },
  },
  methods: {
    loadBanners() {
      console.log("loading home Banners...");
      this.axios
        .post("/banners/get_banners.php", {
          action: "listItems",
          itemsType: this.bannerType,
        })
        .then((response) => {
          console.log(response.data);
          if(!response.data.success){
            console.log("no banners found for this category");
            return;
          }
          this.banners = response.data.items;
          if(this.banners.length > 0){
            this.hasBanner = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showImage() {
      if (this.hasBanner) {
        let bannerCount = this.banners.length;
        let selectRandom = Math.floor(Math.random() * bannerCount);
        this.selectedBanner = this.banners[selectRandom];
        let image = this.banners[selectRandom].images[this.selectedLang].image;
        return this.$env.bannersURL + image;
      } else {
        return "https://salex.md/img/banners/1345x150.png";
      }
    },
    openPage() {
       // console.log(this.selectedBanner);
        let url = this.selectedBanner.linkURL;
        let target = this.selectedBanner.linkTarget;
        if(target == ""){
            target = "_blank";
        }
        if (url == "") {
            return;
        }           
      window.open(url, target);
    },
  },
};
</script>

<style scoped>
.slider-wrapper{
  position: relative;
}

.flicking-panel {
  position: relative;
  width:100%;
}

.fade-img:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 25%,
    rgb(245, 245, 245) 100%
  );
  height: 60%;
}

.top-carusel {
  position: absolute;
  /* width: calc(100% + 20px); */
  /* transform: translate(-10px, 0px); */
  height: 400px;
  margin-top: -490px;
  z-index: 0;
}
.top-spacer {
  height: 445px;
}

/*mobile braking */
@media (max-width: 768px) {
  .top-carusel {
    height: 300px;
    margin-top: -390px;
  }
  .top-spacer {
    height: 345px;
  }
}

.content-banner{
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
}

.banner-image{
  pointer-events: none;
}

</style>
