<template>
  <footer class="footer mt-6">
    <!-- Three columns created using columns class of Bulma -->
    <div class="container">
      <div class="columns">
        <!-- Column 1 -->
        <div class="column flex-col pr-3">
          <div>
            <div><img src="@/assets/sx_logo_light.svg" alt="" width="180px" /></div>
          </div>
          <small class="has-text-ddlight"
          >{{ currentYear }} &copy; Foxsolution SRL<br/>All Rights Reserved.
        </small>

          <!-- <div class="subscribe mt-4">
            <form>
              <b-field>
                <b-input placeholder="Email" type="email" icon="envelope">
                </b-input>
                <p class="control">
                  <b-button type="is-primary" label="Subscribe" />
                </p>
              </b-field>
            </form>
          </div> -->
        </div>

        <!-- Column 2 -->
        <div class="column">
          <h4 class="bd-footer-title has-text-weight-medium has-text-justify">
            {{$t('Information')}}
          </h4>

          <!-- Column 2 lists with links -->
          <p class="bd-footer-link" v-if="hasMenuItems">
            <template v-for="item in menuItems">
              <router-link :key="item.id" :to="'/article/'+item.slug" v-if="item.col==1">
                <span class="icon-text">
                  {{ item[labels[lang]] }}
                </span>
              </router-link>
              <br v-if="item.col==1" />
            </template>
          </p>
        </div>

        <!-- Column 3 -->
        <div class="column">
          <h4 class="bd-footer-title has-text-weight-medium has-text-justify">
            {{$t('Customer Support')}}
          </h4>

          <!-- Column 3 lists with links -->
          <p class="bd-footer-link">
            <template v-for="item in menuItems">
              <router-link :key="item.id" :to="'/article/'+item.slug" v-if="item.col==2">
                <span class="icon-text">
                  {{ item[labels[lang]] }}
                </span>
              </router-link>
              <br v-if="item.col==2" />
            </template>          
          </p>
        </div>

                <!-- Column 4 -->
                <div class="column">
          <h4 class="bd-footer-title has-text-weight-medium has-text-justify">
            {{$t('Social Media')}}
          </h4>

          <!-- Column 4 lists with links -->
          <p class="bd-footer-link">
            <!-- <template v-for="item in menuItems">
              <router-link :key="item.id" :to="'/article/'+item.slug" v-if="item.col==3">
                <span class="icon-text">
                  {{ item[labels[lang]] }}
                </span>
              </router-link>
              <br v-if="item.col==3" />
            </template>              -->
            <div class="social-icons">
            <button
              class="ico-bt-lg light-bt"
              @click="openSocialLink('facebook')"
            >
              <b-icon icon="synho-social social-facebook-square"></b-icon>
            </button>
            <button
              class="ico-bt-lg light-bt"
              @click="openSocialLink('instagram')"
            >
              <b-icon icon="synho-social social-instagram"></b-icon>
            </button>
            <button
              class="ico-bt-lg light-bt"
              @click="openSocialLink('twitter')"
            >
              <b-icon icon="synho-social social-telegram"></b-icon>
            </button>
            <button
              class="ico-bt-lg light-bt"
              @click="openSocialLink('viber')"
            >
              <b-icon icon="synho-social social-viber"></b-icon>
            </button>
            <button
              class="ico-bt-lg light-bt"
              @click="openSocialLink('whatsapp')"
            >
              <b-icon icon="synho-social social-whatsapp"></b-icon>
            </button>
          </div>
          </p>
        </div>

        
      </div>
<!-- 
      <div class="container has-text-centered">
        <small class="has-text-ddlight"
          >{{ currentYear }} &copy; FoxSolutions SRL | All Rights Reserved.
        </small>
      </div> -->
    </div>
  </footer>
</template>

<script>
import { mapState } from "vuex";
import translationHelper from "@/mixins/translationHelper";
import { RouterLink } from "vue-router";
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
      menuItems: [],
      hasMenuItems: false,
    };
  },
  mixins: [translationHelper],
  computed: {
    ...mapState({
      lang: (state) => state.lang,
      categories: (state) => state.categories,
      isMobile: (state) => state.isMobile,
      isLogged: (state) => state.users.isLogged,
    }),

  },
  components: { RouterLink },
  mounted() {
    this.getMenuList();
  },
  methods: {
    getMenuList(){
      this.axios.post('/pages/get_pages.php',
      {
        action: 'listMenuItems',
      }
      )
      .then((response) => {
        console.log("got footer menu list:");
        console.log(response.data);
        if(response.data.success){
          this.menuItems = response.data.items;
          this.hasMenuItems = true;
        }else{
          console.log("error getting menu items");
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    openSocialLink(social) {
      console.log(social);
      if(social == 'facebook'){
        window.open("https://www.facebook.com/profile.php?id=61554926047692", "_blank");
      }else if(social == 'instagram'){
        window.open("https://www.instagram.com/salex.md/", "_blank");
      }else if(social == 'telegramm'){
        window.open("https://t.me/salex_md", "_blank");
      }else if(social == 'viber'){
        window.open("viber://chat?number=+37361011777", "_blank");
      }else if(social == 'whatsapp'){
        window.open("https://wa.me/37361011777", "_blank");
      }
    },
    openModal() {
    console.log('openModal adstest');
      this.$store.commit("modals/showModal", "adstest");
    },
  },
};
</script>
<style scoped>
.flex-col{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
}

.synho-social {
  font-size: 2rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.social-bt {
  text-align: center;
  color: #4a4f54 !important;
  font-size: 1.5rem;
  font-weight: 300;
  cursor: pointer;
}

.social-bt:hover {
  color: #ffffff !important;
  background-color: #7cbc4b;
}

.bd-footer-link a:hover{
  color: #ffffff !important;
}

/*mobile style*/

@media screen and (max-width: 1024px){
  .footer{
    padding-bottom: 8rem !important;
  }
}

</style>

