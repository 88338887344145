export default{
  namespaced: true,
  state: {
    toast: {
      show: false,
      text: null,
      type: null,
    }
  },
  getters: {
  },
  mutations: {
    showToast(state, payload) {
      state.toast.show = payload.show;
      state.toast.text = payload.text;
      state.toast.type = payload.type;
      setTimeout(() => {
        state.toast.show = false;
      },100);
    },
    hideToast(state) {
      state.toast.show = false;
      state.toast.text = null;
      state.toast.type = null;
    },
  },
  actions: {
  },
  modules: {
  }
}
