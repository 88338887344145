<template>

        <div class="icon-wrapper">
          <div class="circle-icon" :style="`background-color: ${category.color};`">
            <div class="circle-icon-gradient">
              <div class="icon icon-shdow" :class="'salex sx-'+category.icon"></div>
            </div>
          </div>
        </div>


</template>

<script>
export default {
  name: "CategoriesIcon",
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped>

.icon-wrapper{
  position: relative;
  width: 60px;
  height: 60px;
}
.circle-icon{
  left:0;
  right:0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: #000000;
  border-radius: 50%;
  /* filter: drop-shadow(5px 5px 4px #00000036); */
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.circle-icon:hover{
  width: 80px;
  height: 80px;
  transform: translate(-10px, -10px);
  /* transform: translate(-10px, -10px); */
}
.circle-icon .icon{
  color: #ffffffec;
  font-size: 1.8rem;
  transition: all 0.1s ease-out;
}
.circle-icon:hover .icon{
  font-size: 3rem;
  color: #ffffffec;
}
.icon-shdow{
  filter: drop-shadow(3px 3px 2px #00000036);
}
.circle-icon-gradient{
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0,0,0,0.09) 0%, rgba(0,0,0,0) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-shadow: 2px 2px #00000063; */
}
</style>
