<template>
  <b-sidebar
    v-model="isActive"
    :can-cancel="true"
    :fullheight="true"
    :fullwidth="true"
    type="is-white"
    class="filter-sidebar"
    @close="didCancel()"
  >
    <div class="close-sidebar">
      <div class="sidebar-title">{{$t('Categories') }}</div>
      <button type="button" class="delete" @click="closeModal()" />
    </div>

    <div class="sidebar-blocks">
      <div class="top-block scroll-pages-menu">
        <div
          class="block"
          @keydown.down.prevent="focusOnNextDiv"
          @keydown.up.prevent="focusOnPreviousDiv"
          @keydown.enter.prevent="detectFocused"
        >
        <!-- <div>
          Categories
        </div> -->

        <b-button v-if="catSelected" class="my-3" type="is-warning" expanded @click="resetSelection()" icon-left="angle-left">Back</b-button>

        <div v-if="!catSelected" class="side-link" v-for="cat in categories" @click="setCategoryData(cat)">          
            <div class="side-link-text"><div class="slide-circle-icon mr-1" :style="'background-color:'+cat.color"><div class="icon icon-shdow" :class="'salex sx-' + cat.icon"></div></div> <div>{{cat[labels[lang]]}}</div></div>
          </div>

          <div v-if="currentCategoryData" class="group-devider" v-for="(group, index) in currentCategoryData.groups">
              <div class="group-names mb-3">{{group[labels[lang]]}}</div>
                <div v-for="subcat in group.subcategories">
                  <div class="side-sublinks" @click="goTo(currentCategoryData.slug+'/'+subcat.slug)">{{subcat[labels[lang]]}}</div>
                </div>
            </div>

        <!-- <b-menu :activable="false">
          <div class="side-link" v-for="cat in categories" @mouseover="setCategoryData(cat)"  @click="goTo(cat.slug)">
            <div class="side-link-text">{{cat[labels[lang]]}}</div>
          </div>
      <b-menu-list>
        <b-menu-item v-for="cat in categories"
          icon="grid-2"
          :label="$t('Dashboard')"
          @click="goTo('my-dashboard')"
          :active="isActive == 'my-dashboard'"
          ></b-menu-item>
        </b-menu-list> -->

        </div>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import translationHelper from "@/mixins/translationHelper";

export default {
  name: "CategoriesSidebar",
  mixins: [translationHelper],
  data() {
    return {
      currentCategoryData: {},
      catSelected: false,
    };
  },
  mounted() {
    console.log("mounted categories Sidebar");
  },
  computed: {
    ...mapState(["lang", "categories"]),
    ...mapGetters(["getSubcategories"]),
    isActive: {
      get: function () {
        return this.$store.state.modals.catsidebar;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "catsidebar");
        return false;
      },
    },
  },
  methods: {
    closeModal(){
      this.$store.commit("modals/hideModal", "catsidebar");
      this.$root.$emit('didCloseCatSidebar');
      this.resetSelection();
    },
    didCancel(){
      this.$root.$emit('didCloseCatSidebar');
    },
    hideModal(){
      this.$store.commit("modals/hideModal", "catsidebar");
      this.resetSelection();
    },
    setCategoryData(cat){
      this.currentCategoryData = cat;
      this.catSelected = true;
    },
    goTo(path){
      this.$router.push("/category/"+path);
      this.$root.$emit('didSelectCatSidebar');
      this.hideModal();
    },
    resetSelection(){
      this.currentCategoryData = {};
      this.catSelected = false;
    },
  },
  watch: {
    // subID: function (newVal, oldVal) {
    //   console.log("subID changed", newVal, oldVal);
    //   if (newVal !== null) {
    //     //this.loadAttributes();
    //     this.selectedOptionsShow = [];
    //     this.selectedOptions = [];
    //   } else {
    //     //this.attributesData = [];
    //     this.showSearchOptions = false;
    //   }
    // },
    isActive: function () {
      if (this.isActive) {
        console.log("Categories Sidebar is active");
      }
    },
  },
};
</script>

<style scoped>
.filter-sidebar{
  overflow: hidden;
}

.search-box {
  position: relative;
  margin-bottom: 2rem;
  background-color: hsl(0deg, 0%, 100%);
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: hsl(0deg, 0%, 29%);
  display: block;
  /* padding: 1.25rem; */
  transition: all 0.3s ease;
}

.search-box-wrap {
  padding: 1.25rem 1.25rem;
}

.close-sidebar {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sidebar-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #4a4a4a;
}

.scroll-pages-menu {
  height: 88dvh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 10px;
  padding-right: 10px;
}

.scroll-pages-menu::-webkit-scrollbar {
  width: 4px;
}

.scroll-pages-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.scroll-pages-menu::-webkit-scrollbar-thumb {
  background: #979797;
}

.side-link{
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 20px;
}
.side-link:hover{
  background-color: #e8ebee;
  color: #000; /*#ff5a5f*/;
}

.side-link-text{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.side-sublinks{
  color: #6d767e;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 5px;
}

.side-sublinks:hover{
  color: #7cbc4b /*#2e3135*/;
}

.group-names{
  color: #4a4a4a;
  font-size: 1.2rem;
  margin-bottom: 20px;
  width: 100%;
  line-height: 1.2;
}

.group-devider{
  margin-bottom: 20px;
}

.slide-circle-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px; 
  border-radius: 50%;
  overflow: hidden;
  color: #fff;
}
</style>
