<template>
  <section>
    <b-table
      :data="isEmpty ? [] : listItems"
      ref="table"
      :loading="isLoading"
      :opened-detailed="defaultOpenedDetails"
      detailed
      detail-key="id"
      :show-detail-icon="showDetailIcon"
      class="advers-list-table"
    >
      <!-- <b-table-column
        field="id"
        label="ID"
        width="40"
        numeric
        sortable
        v-slot="props"
      >
        {{ props.row.id }}
      </b-table-column> -->

      <b-table-column width="110" field="image" v-slot="props">
        <figure class="list-image">

        <img :src="showImage(props.row)" alt="Product Image"/>
      </figure>
      
      </b-table-column>

      <b-table-column field="title" :label="$t('Title')" sortable v-slot="props">
        <!-- <a class="s-name" @click="toggle(props.row)" v-html="props.row.name"></a> -->
        <router-link :to='"/advert/"+props.row.id' class="list-link">{{ props.row["ad_title_"+props.row.ad_lang] }}</router-link><br/>
        <!-- <a class="list-link" @click="openAdv(props.row.id)">{{ props.row["ad_title_"+props.row.ad_lang] }}</a><br/> -->
        <span class="truncate-line-clamp">{{ props.row["ad_descr_"+props.row.ad_lang] }}</span>

        <div class="is-size-7 has-text-grey-light">{{formatDate(props.row.created, lang)}}</div>
      </b-table-column>

      <b-table-column field="category" :label="$t('Category')" sortable v-slot="props">
        {{formatCategory(props.row.cat_id)}}
      </b-table-column>

      <!-- <b-table-column field="vews" :label="$t('Views')" sortable v-slot="props">
        {{props.row.ad_views_today +"/"+ props.row.ad_views_total}}
      </b-table-column> -->

      <b-table-column
        custom-key="actions"
        :label="$t('Actions')"
        v-slot="props"
        width="90"
        centered
      >
      <button class="ico-bt mr-2 red-bt" :class="favs.includes(props.row.id) ? 'active':''" @click="toggleFavourite(props.row.id)">
        <b-icon icon="heart"></b-icon>
      </button>

      <button class="ico-bt mr-2 blue-bt" @click="goTo(props.row.id)">
        <b-icon icon="eye"></b-icon>
      </button>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong class="is-pop">{{$t("Title")}}: </strong> <span class="s-name">{{ props.row["ad_title_"+props.row.ad_lang] }}</span><br />
                <strong class="is-pop">{{$t("Description")}}: </strong> <span class="s-name">{{ props.row["ad_descr_"+props.row.ad_lang] }}</span><br />

                <strong class="is-pop">{{$t("Address")}}: </strong> {{ props.row.ad_address }}<br />
                <strong class="is-pop">{{$t("City")}}: </strong> {{ props.row.ad_city}}<br />

                <strong class="is-pop">{{$t("Category")}}: </strong>  {{formatCategory(props.row.cat_id)}}<br />
                <strong class="is-pop">{{$t("Subcategory")}}: </strong> {{ formatSubcategory(props.row.cat_id, props.row.sub_id)}}<br />
                <!-- <strong class="is-pop">Password: </strong> {{ props.row.pass
                }} -->
              </p>
            </div>
          </div>
        </article>
      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Adverts.</p>
          </div>
        </section>
      </template>
    </b-table>

  </section>
</template>
<script>

import { mapState } from "vuex";

import formatMixin from "@/mixins/formatMixin";

export default {
  name: "AdsList.vue",
  props: {
    listItems: {
      type: Array,
      default: () => [],
    }
  },
  mixins: [formatMixin],
  data() {
    return {
      defaultOpenedDetails: [0],
      showDetailIcon: true,
      isEmpty: false,
      isLoading: false,
      labels:{
        en:"labelEN",
        ru:"labelRU",
        ro:"labelRO"
      },
    };
  },

  computed:{
    ...mapState(["lang"]),
    //map user
    ...mapState({
      favs: (state) => state.favorites.favs,
      userID: (state) => state.users.user.id,
    }),
    // isFavorite(){
    //   return this.favs.includes(this.advertData.id);
    // },

  },

  methods: {
    showImage(advertData){
      if(advertData.ad_images.images.length == 0){
        return 'https://salex.md/img/noimage.png';
      }else{
        let image = advertData.ad_images.images[0].image.replace(/\.[^/.]+$/, "") + "_h400.webp";
        return this.$env.imagesURL+"gallery/400/" + image;
        //return this.$env.imagesURL + advertData.ad_images.images[0].image;
      }     
    },
    openAdv(id) {
      this.$router.push({ path: "advert/"+id });
    },
    formatCategory(id) {
      //find category by id in an array of objects
      let category = this.$store.state.categories.find(
        (category) => category.id == id
      );
      return category[this.labels[this.lang]];
    },
    formatSubcategory(catid, subid) {
      //find category by id in an array of objects
      let category = this.$store.state.catrgoriesFlat.find(
        (category) => category.id == catid
      );

      let subcategory = category.subcategories.find(
        (subcategory) => subcategory.id == subid
      );
      return subcategory[this.labels[this.lang]];
    },
    // formatDate(dateString) {
    //   const date = new Date(dateString);
    //   const day = date.getDate();
    //   const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    //   const month = monthNames[date.getMonth()];
    //   const year = date.getFullYear();

    //   const formattedDate = `${day} ${month} ${year}`;
    //   return formattedDate;
    // },
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    },
    goTo(id) {
      this.$router.push({ path: "/advert/"+id});
    },
    toggleFavourite(id) {
      if (this.favs.includes(id)) {
        this.removeFavourite(id);
      } else {
        this.addFavourite(id);
      }
    },
    addFavourite(id) {
      console.log("add to favorites", id);
      this.axios
        .post("/favorites/manage_favorites.php", {
          action: "addFav",
          advID: id,
          token: localStorage.sxToken,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            //this.$store.commit("users/addFavorites", id);
            this.$store.dispatch("favorites/loadFavorites");
          } else {
            console.log("could not update favorites");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    removeFavourite(id) {
      console.log("remove from favorites", id);
      this.axios
        .post("/favorites/manage_favorites.php", {
          action: "deletFav",
          advID: id,
          token: localStorage.sxToken,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            //this.$store.commit("users/remFavorites", id);
            this.$store.dispatch("favorites/loadFavorites");
          } else {
            console.log("could not update favorites");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

};
</script>
<style scoped>
.s-name{
  text-transform: capitalize;
}

.list-link{
  color: #65bb24;
  text-transform: capitalize;
}

.list-link:hover{
  color:blueviolet;
  text-decoration: underline;
}

.list-image{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    overflow: hidden;
}

.list-image img{
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.truncate-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; 
  /* max: 250px; */
  max-width: 500px;
  overflow: hidden;
}
</style>