export default {
	data(){
		return {
		  titlesLabels: {
			en: "ad_title_en",
			ru: "ad_title_ru",
			ro: "ad_title_ro"
		  },
		  descrLabels: {
			en: "ad_descr_en",
			ru: "ad_descr_ru",
			ro: "ad_descr_ro"
		  },
		  labels:{
			en:"labelEN",
			ru:"labelRU",
			ro:"labelRO"
		  },
		  titles:{
			en:"titleEN",
			ru:"titleRU",
			ro:"titleRO"
		  },
		  contents:{
			en:"contentEN",
			ru:"contentRU",
			ro:"contentRO"
		  },
		  descriptions:{
			en:"descriptionEN",
			ru:"descriptionRU",
			ro:"descriptionRO"
		  },
		};
	  },
}