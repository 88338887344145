import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import users from "./modules/users";
import modals from "./modules/modals";
import toast from "./modules/toast";
import adverts from "./modules/adverts";
import favorites from "./modules/favorites";
import checkinbox from "./modules/checkinbox";

const location = require("@/static/location.json");

Vue.use(Vuex);
let apiUrl = process.env.VUE_APP_API_URL;

export default new Vuex.Store({
  state: {
    // user: {
    //   isLogged: false,
    //   name: "",
    //   email: "",
    //   token: "",
    // },
    categories: [],
    catrgoriesFlat: [],
    existingSlugs: [],
    allLabels: [],
    searchTerm: "",
    lang: "ro",
    showMenu: false,
    isMobile: false,
    locations: location.data,
    tempToken: null,
    tempAttributes: null,
    //testing
    homeBigTop: false,
    homeBigTopE: false,
    homeBig: false,
    homeHorisontal: false,
    advertHorisontal: false,
    advertSquare: false,
    currencyRates: null,
    cookie: false,
  },
  getters: {
    getLocations: (state) => {
      return state.locations;
    },
    getSubcategories: (state) => {
      let subcategories = [];
      state.catrgoriesFlat.forEach((category) => {
        category.subcategories.forEach((subcategory) => {
          subcategories.push(subcategory);
        });
      });
      return subcategories;
    },
  },
  mutations: {
    setCategories(state, categories) {
      state.categories = categories;
    },
    setCategoriesFlat(state, flatCategories) {
      console.log("setCategoriesFlat:", flatCategories);
      state.catrgoriesFlat = flatCategories;
    },
    setLang(state, lang) {
      console.log("setLanguage:", lang);
      state.lang = lang;
      localStorage.setItem("sxLang", lang);
    },
    setMenu(state, payload) {
      console.log("setMenu:", payload);
      state.showMenu = payload;
    },
    setMobile(state, payload) {
      console.log("setMobile:", payload);
      state.isMobile = payload;
    },
    setSearchTerm(state, payload) {
      console.log("setSearchTerm:", payload);
      state.searchTerm = payload;
    },
    setTempToken(state, payload) {
      console.log("setTempToken:", payload);
      state.tempToken = payload;
    },
    setTempAttributes(state, payload) {
      console.log("setTempAttributes:", payload);
      state.tempAttributes = payload;
    },
    //testing
    setBanner(state, payload) {
      console.log("setBanner:", payload);
      state[payload.bannerType] = payload.value;
    },
    setExistingSlugs(state, payload) {
      console.log("setExistingSlugs:", payload);
      state.existingSlugs = payload;
    },
    setRates(state, payload) {
      console.log("setRates:", payload);
      state.currencyRates = payload;
    },
    setCookie(state, payload) {
      console.log("setCookie:", payload);
      state.cookie = payload;
      localStorage.setItem("sxCookie", payload);
    }
  },
  actions: {
    // login({ commit }, user) {
    //   commit("login", user);
    // },
    // logout({ commit }) {
    //   commit("logout");
    // },
    loadCategories({ commit }) {
      axios
        .post(`${apiUrl}/categories/get_categories_full.php`, {
          action: "getList",
        })
        .then((response) => {
          let categories = response.data.categories;
          //console.log(response.data.categories);
          commit("setCategories", categories);
          //console.log(categories);

          // extract categories from groups and place them under the category // pop the groups
          let categoriesAndSubcategories = [];
          let tempSlugs = [];
          let tempCategories = [...categories];

          tempCategories.forEach((category) => {
            tempSlugs.push(category.slug);
            category.groups.forEach((group) => {
              group.subcategories.forEach((subcategory) => {
                tempSlugs.push(subcategory.slug);
                categoriesAndSubcategories.push(subcategory);
              });
            });
            category.subcategories = categoriesAndSubcategories;
            // category.groups = [];
            // delete category.groups;
            categoriesAndSubcategories = [];
          });

          commit("setCategoriesFlat", tempCategories);
          commit("setExistingSlugs", tempSlugs);
          //console.log("tempCategories", tempCategories);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // get temp token
    getTempToken({ commit }) {
      axios
        .post(`${apiUrl}/token.php`, {
          action: "getToken",
        })
        .then((response) => {
          //console.log("token response", response);
          if (response.data.success === false) {
            console.log("error");
            return;
          }
          let token = response.data.token;
          commit("setTempToken", token);
        })
        .catch((error) => {
          console.log("token error ",error);
        });
    },
    async getCurrencyRates({ commit }) {
      try {
          const response = await axios.post(`${apiUrl}/functions/rates.php`, {
            action: 'getRates',
          });
          commit('setRates', response.data.Valutes);
        } catch (error) {
          console.error('Error fetching rates:', error);
        }
    },
  },
  modules: {
    users,
    modals,
    toast,
    adverts,
    favorites,
    checkinbox,
  },
});
