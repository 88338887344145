<template>
  <section>
    <b-modal :active.sync="isActive" has-modal-card trap-focus aria-role="dialog" aria-modal :can-cancel="false">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">{{$t('Create an account')}}</p>
          <button type="button" class="delete" @click="closeModal()" />
        </header>
        <section class="modal-card-body">
          <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
          <form autocomplete="off" v-if="form.visible">
          <b-field label="Phone">
            <p class="control">
              <span class="button is-static" disabled>+373</span>
            </p>
            <b-input type="text" v-model="form.phone.data" placeholder="Your Phone" disabled expanded></b-input>
          </b-field>
          <b-field :label="nameLabels[form.accountType.data]" :type="form.username.status" :message="form.username.message">
            <b-input type="text" v-model="form.username.data" :placeholder="'Your ' + nameLabels[form.accountType.data]" required expanded></b-input>
            <p class="control">
              <b-tooltip label="Public Information" position="is-left">
                <b-button class="button is-static" icon-left="circle-question"></b-button>
              </b-tooltip>
            </p>
          </b-field>
          <b-field  label="Email" :type="form.email.status" :message="form.email.message">
            <b-input type="email" v-model="form.email.data" placeholder="Your email"></b-input>
          </b-field>
          <b-field label="Account Type">
            <b-radio-button v-model="form.accountType.data" 
                native-value="0"
                type="is-success is-light" expanded>
                <b-icon icon="user"></b-icon>
                <span>Personal</span>
            </b-radio-button>
  
            <b-radio-button v-model="form.accountType.data"
                native-value="1"
                type="is-info is-light" expanded>
                <b-icon icon="users"></b-icon>
                <span>Company</span>
            </b-radio-button>
        </b-field>
          <!-- <b-field class="m-t-md" :label="this.form.human.label" :type="form.human.status"
            :message="form.human.message" horizontal>
            <b-input type="text" v-model="form.human.data" placeholder="Your answer" required></b-input>
          </b-field> -->
        </form>
          <b-message class="mt-2" :type="form.status" v-if="form.result">
            {{ form.message }}
          </b-message>
          <div v-if="!form.visible" class="has-text-centered">
            <button class="button is-primary" type="button" @click="openLogin()">
              Login Now
            </button>
          </div>
        </section>
        <footer class="modal-card-foot" v-if="form.visible">
          <button class="button" type="button" @click="closeModal()">
            Cancel
          </button>
          <button class="button is-info" @click="onSubmit()">
            Register
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import formCheck from "@/mixins/formCheck";

export default {
  name: "RegisterModal",
  mixins: [formCheck],
  data() {
    return {
      isLoading: false,
      countErrors: 0,
      nameLabels: ["Usrename", "Company Name"],
      form: {
        username: {
          data: "",
          status: "",
          message: ""
        },
        email: {
          data: "",
          status: "",
          message: ""
        },
        phone: {
          data: "",
          status: "",
          message: ""
        },
        accountType: {
          data: "0",
          status: "",
          message: ""
        },
        human: {
          lable: "",
          summ: null,
          data: null,
          status: "",
          message: ""
        },
        visible: true,
        message: "",
        result: false,
        status: ""
      }
    };
  },
  methods: {
    capitalizeWords(string) {
      let arr = string.split(" ");
      return arr.map(element => {
        return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
      }).join(" ");
    },
    generateCapcha() {
      let var0 = Math.floor(Math.random() * 9) + 1;
      let var1 = Math.floor(Math.random() * 9) + 1;
      this.form.human.summ = var0 + var1;
      this.form.human.label = var0 + '+' + var1 + '=';
    },
    onSubmit() {
      //evt.preventDefault();
      //this.$store.commit("DO_LOGIN", true);
      //this.$store.commit("SHOW_MODAL", false);
      //const whitespaceRemoved = str.replace(/\s/g, '');
      if (this.usernameCheck("username")) {
        //this.form.visible = false;
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post("users/register.php", {
            action: "register",
            username: this.form.username.data,
            email: this.form.email.data,
            phone: this.form.phone.data,
            accountType: this.form.accountType.data,
            token: this.$store.state.tempToken,
          })
          .then(response => {
            this.isLoading = false;
            this.form.result = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
              this.form.visible = false;
              //this.$store.commit("DO_LOGIN", true);
              //this.$store.dispatch("SET_USERDATA", response.data);
              //this.$router.push({ path: "/category/all" });
              //this.closeModal();
            } else {
              this.form.status = "is-danger";
              if (response.data.type == "email") {
                this.form.email.status = "is-danger";
              }              
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error);
            //this.hasError();
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },
    resetForm() {
      this.form.username.data = "";
      this.form.username.status = "";
      this.form.username.message = "";

      this.form.email.data = "";
      this.form.email.status = "";
      this.form.email.message = "";

      this.form.phone.data = "";

      this.form.accountType.data = "0";

      // this.form.pass.data = "";
      // this.form.pass.status = "";
      // this.form.pass.message = "";
      this.form.message = "";
      this.form.result = false;
      this.form.status = "";
    },
    closeModal() {
      this.resetForm();
      localStorage.removeItem("regPhone");
      this.$store.commit("modals/hideModal", "register");
    },
    openLogin() {
      this.$store.commit("modals/showModal", "login");
      this.closeModal();
    }
  },
  computed: {
    isActive: {
      get: function () {
        return this.$store.state.modals.register;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "register");
        return false;
      }
    }
  },
  watch: {
    isActive: function () {
      this.resetForm();
      //this.generateCapcha();
      this.form.phone.data = localStorage.regPhone;
    }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between;
  /* flex-end; */
}
</style>
