import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
  },
  {
    path: "/pricing",
    name: "PricingView",
    component: () =>
      import(/* webpackChunkName: "sx-pricing" */ "../views/PricingView.vue"),
  },
  {
    path: "/article/:slug?",
    name: "ArticleView",
    component: () =>
      import(/* webpackChunkName: "sx-article" */ "../views/pages/ArticleView.vue"),
  },
  {
    path: "/advert/:advertId",
    name: "AdvertView",
    component: () =>
      import(/* webpackChunkName: "sxadvert" */ "../views/AdvertView.vue"),
  },
  {
    path: "/profile/",
    name: "ProfileView",
    component: () =>
      import(/* webpackChunkName: "sx-profile" */ "../views/ProfileView.vue"),
  },
  {
    path: "/category/:categorySlug/:subCategorySlug?/:page?",
    name: "CategoryView",
    component: () =>
      import(/* webpackChunkName: "sx-category" */ "../views/CategoryView.vue"),
  }, 
  {
    path: "/search/:page?",
    name: "SearchResultsView",
    component: () =>
      import(/* webpackChunkName: "sx-search" */ "../views/SearchView.vue"),
  },
  {
    path: "/recent/:page?",
    name: "RecentView",
    component: () =>
      import(/* webpackChunkName: "sx-recent" */ "../views/RecentView.vue"),
  },
  {
    path: "/add-advert",
    name: "AddAdvertView",
    component: () =>
      import(/* webpackChunkName: "sx-add-advert" */ "../views/AddAdvertView.vue"),
  },
  //user account ===================================================================
  {
    path: "/my-dashboard",
    name: "DashboardView",
    component: () =>
      import(/* webpackChunkName: "sx-dashboard" */ "../views/user-account/DashboardView.vue"),
  },
  {
    path: "/my-profile",
    name: "MyProfileView",
    component: () =>
      import(/* webpackChunkName: "sx-my-profile" */ "../views/user-account/MyProfileView.vue"),
  },
  {
    path: "/my-adverts",
    name: "AdvertsView",
    component: () =>
      import(/* webpackChunkName: "sx-adverts" */ "../views/user-account/AdvertsView.vue"),
  },
  {
    path: "/advert-preview/:advertId",
    name: "AdvertPreviewView",
    component: () =>
      import(/* webpackChunkName: "sx-advert-preview" */ "../views/user-account/AdvertPreviewView.vue"),
  },
  {
    path: "/my-favorites",
    name: "FavoritesView",
    component: () =>
      import(/* webpackChunkName: "sx-favorites" */ "../views/user-account/FavoritesView.vue"),
  },
  {
    path: "/my-bills",
    name: "BillsView",
    component: () =>
      import(/* webpackChunkName: "sx-bills" */ "../views/user-account/BillsView.vue"),
  },
  {
    path: "/my-bills-history",
    name: "BillsHistoryView",
    component: () =>
      import(/* webpackChunkName: "sx-history" */ "../views/user-account/BillsHistoryView.vue"),
  },
  {
    path: "/my-payment-methods",
    name: "PaymentMethodsView",
    component: () =>
      import(/* webpackChunkName: "sx-payment-methods" */ "../views/user-account/PaymentMethodsView.vue"),
  },
  {
    path: "/my-messages",
    name: "MessagesView",
    component: () =>
      import(/* webpackChunkName: "sx-messages" */ "../views/user-account/MessagesView.vue"),
  },
  {
    path: "/my-notifications",
    name: "NotificationsView",
    component: () =>
      import(/* webpackChunkName: "sx-notifications" */ "../views/user-account/NotificationsView.vue"),
  },
  {
    path: "/my-reviews",
    name: "ReviewsView",
    component: () =>
      import(/* webpackChunkName: "sx-reviews" */ "../views/user-account/ReviewsView.vue"),
  },
  {
    path: "/edit-advert",
    name: "EditAdvertView",
    component: () =>
      import(/* webpackChunkName: "sx-edit-advert" */ "../views/user-account/EditAdvertView.vue"),
  },
  {
    path: "/login",
    name: "LoginView",
    component: () =>
      import(/* webpackChunkName: "sx-login" */ "../views/user-account/LoginView.vue"),
  },
  // {
  //   path: "/my-inbox",
  //   name: "MessagesInboxView",
  //   component: () =>
  //     import(/* webpackChunkName: "sx-inbox" */ "../views/user-account/MessagesInboxView.vue"),
  // },
  // {
  //   path: "/my-archive",
  //   name: "MessagesArchiveView",
  //   component: () =>
  //     import(/* webpackChunkName: "sx-archive" */ "../views/user-account/MessagesArchiveView.vue"),
  // },
  ///================================================================================
  {
    path: "/help-desk",
    name: "HelpDeskView",
    component: () =>
      import(/* webpackChunkName: "sx-helpdesk" */ "../views/HelpDeskView.vue"),
  },
  // {
  //   path: "/uploader",
  //   name: "UploaderTestView",
  //   component: () =>
  //     import(/* webpackChunkName: "sx-uploadretest" */ "../views/UploaderTestView.vue"),
  // },
  ///===============================================================================
  //404 page
  {
    path: "*",
    name: "NotFoundView",
    component: () =>
      import(/* webpackChunkName: "sx-notfound" */ "../views/NotFoundView.vue"),
  },



];

// const router = new VueRouter({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes,
// });

// const scrollBehavior = function (to, from, savedPosition) {
//   if (savedPosition) {
//     // savedPosition is only available for popstate navigations.
//     return savedPosition
//   } else {
//     const position = {}

//     // scroll to anchor by returning the selector
//     if (to.hash) {
//       position.selector = to.hash

//       // specify offset of the element
//       if (to.hash === '#anchor2') {
//         position.offset = { y: 100 }
//       }

//       // bypass #1number check
//       if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
//         return position
//       }

//       // if the returned position is falsy or an empty object,
//       // will retain current scroll position.
//       return false
//     }

//     return new Promise(resolve => {
//       // check if any matched route config has meta that requires scrolling to top
//       if (to.matched.some(m => m.meta.scrollToTop)) {
//         // coords will be used if no selector is provided,
//         // or if the selector didn't match any element.
//         position.x = 0
//         position.y = 0
//       }

//       // wait for the out transition to complete (if necessary)
//       this.app.$root.$once('triggerScroll', () => {
//         // if the resolved position is falsy or an empty object,
//         // will retain current scroll position.
//         resolve(position)
//       })
//     })
//   }
// }

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
  //scrollBehavior,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
