<template>
  <b-sidebar
    v-model="isActive"
    :can-cancel="true"
    :fullheight="true"
    :fullwidth="true"
    :right="true"
    type="is-white"
    class="filter-sidebar"
  >
    <div class="close-sidebar">
      <button type="button" class="delete" @click="closeModal()" />
      <div class="sidebar-title">{{$t('Account') }}</div>
    </div>

    <div class="sidebar-blocks">
      <div class="top-block scroll-pages-menu">
        <div class="block">
        <b-dropdown
            v-model="currentLang"
            aria-role="list"
            expanded
            >
            <template #trigger>
                <b-button
                    type="is-light"
                    icon-right="chevron-down"
                    expanded
                    >
                    {{ languageLabels[currentLang] }}
                </b-button>
            </template>


            <b-dropdown-item value="en" aria-role="listitem" @click="langSwitch('en')">
                <span>English</span>
            </b-dropdown-item>

            <b-dropdown-item value="ro" aria-role="listitem" @click="langSwitch('ro')">
                <span>Română</span>
            </b-dropdown-item>

            <b-dropdown-item value="ru" aria-role="listitem" @click="langSwitch('ru')">
                <span>Русский</span>
            </b-dropdown-item>
        </b-dropdown>
      </div>
        <div
          class="block"
          @keydown.down.prevent="focusOnNextDiv"
          @keydown.up.prevent="focusOnPreviousDiv"
          @keydown.enter.prevent="detectFocused"
        >
        <UserMenu :isMobile="true" @didSelect="emitSelected" />


        </div>

      </div>
    </div>
  </b-sidebar>
</template>

<script>
import { mapState } from "vuex";
import translationHelper from "@/mixins/translationHelper";

import UserMenu from "./UserMenu.vue";

export default {
  name: "AccountSidebar",
  mixins: [translationHelper],
  components: {
    UserMenu,
  },
  data() {
    return {
      catSelected: false,
      selectedOptions: "en",
      languageLabels: {
        en: "English",
        ro: "Română",
        ru: "Русский",
      },
    };
  },
  mounted() {
    console.log("mounted Account Sidebar");
  },
  computed: {
    ...mapState(["lang"]),
    isActive: {
      get: function () {
        return this.$store.state.modals.accsidebar;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "accsidebar");
        return false;
      },
    },
    currentLang: {
      get() {
        return this.$store.state.lang;
      },
      set(lang) {
        this.$store.commit("setLang", lang);
      },
    },
  },
  methods: {
    closeModal(){
      this.$store.commit("modals/hideModal", "accsidebar");
      this.$root.$emit('didCloseAccSidebar');
    },
    hideModal(){
      this.$store.commit("modals/hideModal", "accsidebar");
    },
    emitSelected(){
      this.$root.$emit('didSelectAccSidebar');
      this.hideModal();
    },
    langSwitch(lang) {
      console.log(lang);
      this.currentLang = lang;
      this.$i18n.locale = lang;
    },
  },
  watch: {
    // subID: function (newVal, oldVal) {
    //   console.log("subID changed", newVal, oldVal);
    //   if (newVal !== null) {
    //     //this.loadAttributes();
    //     this.selectedOptionsShow = [];
    //     this.selectedOptions = [];
    //   } else {
    //     //this.attributesData = [];
    //     this.showSearchOptions = false;
    //   }
    // },
    isActive: function () {
      if (this.isActive) {
        console.log("Account Sidebar is active");
      }
    },
  },
};
</script>

<style scoped>
.filter-sidebar{
  overflow: hidden;
}

.search-box {
  position: relative;
  margin-bottom: 2rem;
  background-color: hsl(0deg, 0%, 100%);
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: hsl(0deg, 0%, 29%);
  display: block;
  /* padding: 1.25rem; */
  transition: all 0.3s ease;
}

.search-box-wrap {
  padding: 1.25rem 1.25rem;
}

.close-sidebar {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sidebar-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #4a4a4a;
}

.scroll-pages-menu {
  height: 88vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 10px;
  padding-right: 10px;
}

.scroll-pages-menu::-webkit-scrollbar {
  width: 4px;
}

.scroll-pages-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.scroll-pages-menu::-webkit-scrollbar-thumb {
  background: #979797;
}

.side-link{
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 20px;
}
.side-link:hover{
  background-color: #e8ebee;
  color: #000; /*#ff5a5f*/;
}

/* .side-link-text{
  margin-bottom: 5px;
} */

.side-sublinks{
  color: #6d767e;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 5px;
}

.side-sublinks:hover{
  color: #7cbc4b /*#2e3135*/;
}

.group-names{
  color: #4a4a4a;
  font-size: 1.2rem;
  margin-bottom: 20px;
  width: 100%;
  line-height: 1.2;
}

.group-devider{
  margin-bottom: 20px;
}
</style>
