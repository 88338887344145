import axios from "axios";
let apiUrl = process.env.VUE_APP_API_URL;

export default{
  namespaced: true,
  state: {
    messages: 0,
    notifications: 0,
  },
  getters: {
    messagesCount: (state) => {
      return state.messages;
    },
    notificationsCount: (state) => {
      return state.notifications;
    }
  },
  mutations: {
    setMessages(state, payload) {
      console.log("setting messages", payload);
      state.messages = parseInt(payload);
    },
    setNotifications(state, payload) {
      console.log("setting notifications", payload);
      state.notifications = parseInt(payload);
    },
  },
  actions: {
    checkInbox({commit}) {
      console.log("sxToken", localStorage.sxToken);
      console.log("checking Inbox...");
      axios
      .post(`${apiUrl}/users/check_inbox.php`, {
        token: localStorage.sxToken,
        action: "checkInbox",
      })
      .then((response) => {
        console.log("checking inbox", response.data);
        if(response.data.success){          
            commit("setMessages", response.data.messages);         
            commit("setNotifications", response.data.notifications);         
        }
      });
    },
  },
  modules: {
  }
}
