export default{
  namespaced: true,
  state: {
    advert: {
      isEditingID: null,
    },
    sorting: null,
    isSorted: false,
  },
  getters: {
    isEditingAdvert: (state) => {
      return state.advert.isEditingID;
    }
  },
  mutations: {
    setEditing(state, payload) {
      localStorage.removeItem("sxEditingAdvert");
      localStorage.sxEditingAdvert = payload;
      state.advert.isEditingID = payload;
    },
    unsetEditing(state){
      localStorage.removeItem("sxEditingAdvert");
      state.advert.isEditingID = null;
    },
    setSorting(state, payload) {
      state.sorting = payload;
      state.isSorted = true;
    },
    resrtSorting(state){
      state.sorting = null;
      state.isSorted = false;
    },
  },
  actions: {
  },
  modules: {
  }
}
