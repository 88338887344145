export default {
  methods: {
    openModal(modal) {
      console.log("openModal:", modal);
      this.$store.commit("modals/showModal", modal);
    },
    closeModal(modal) {
      console.log("closeModal:", modal);
      this.$store.commit("modals/hideModal", modal);
    }
  },
};
