<template>
  <div id="app">

    <Navbar />
    <MegaMenu />
    <div class="main">
      <router-view />
    </div>
    <Footer />
    <BottomNav class="hide-on-desktop" />
    <CategoriesSidebar/>
    <AccountSidebar/>
    <LoginModal/>
    <RegisterPhoneModal/>
    <RegisterModal/>
    <RestoreModal/>
    <ChangePasswordModal/>
    <ExchangeModal/>
    <FloatingChat/>

    <!-- <InviteCover/> -->
    <!-- <lottie-vue-player :src="`animations/robo_anim.json`"
                           :autoplay="true"
                           :player-controls="false"
                           :loop="true"
                           style="width: 100%; height:400px">
        </lottie-vue-player> -->
  </div>
</template>

<script>
//import InviteCover from "./components/InviteCover.vue";

import Navbar from "@/components/navigation/Navbar.vue";
import Footer from "@/components/navigation/Footer.vue";
import MegaMenu from "@/components/navigation/MegaMenu.vue";
import BottomNav from "./components/navigation/BottomNav.vue";
import CategoriesSidebar from "@/components/navigation/CategoriesSidebar.vue";
import AccountSidebar from "@/components/navigation/AccountSidebar.vue";

import LoginModal from "@/components/userModals/LoginModal.vue";
import RegisterPhoneModal from "@/components/userModals/RegisterPhoneModal.vue";
import RegisterModal from "@/components/userModals/RegisterModal.vue";
import RestoreModal from "@/components/userModals/RestoreModal.vue";
import ChangePasswordModal from "@/components/userModals/ChangePasswordModal.vue";



import ExchangeModal from "@/components/modals/ExchangeModal.vue";
import FloatingChat from "./components/chat/FloatingChat.vue";


import detectMobile from "@/mixins/detectMobile.js";


//import UserMenu from "@/components/navigation/UserMenu.vue";

export default {
  name: "app",
  components: {
    Navbar,
    Footer,
    MegaMenu,
    BottomNav,
    CategoriesSidebar,
    AccountSidebar,
    LoginModal,
    RegisterModal,
    RegisterPhoneModal,
    RestoreModal,
    ChangePasswordModal,
    ExchangeModal,
    FloatingChat,
    //UserMenu,
    //testing
    //InviteCover,
  },
  data() {
    return{
      isMobile: false,
    };
  },
  mixins: [detectMobile],
  beforeMount() {
   console.log("APP BEFORE MOUNT!");
   this.$store.dispatch("loadCategories");
   this.$store.dispatch("getTempToken");
   //detect mobile
    this.$store.commit("setMobile", this.detectMobile());
    if( this.detectMobile() ){
      this.isMobile = true;
    }
  },
  mounted() {
    window.addEventListener('popstate', () => {
      this.handleBackButton();
    });
    //---------------------------------------------//
    console.log("APP MOUNTED!");
    //const apiUrl = process.env.VUE_APP_API_URL;
    //console.log(apiUrl);
    //this.$store.dispatch("loadCategories");

    if(localStorage.sxLang){
      this.$store.commit("setLang", localStorage.sxLang);
      this.$i18n.locale = localStorage.sxLang;
    }
    if(localStorage.sxInviteCode){
      this.$store.commit("modals/hideModal", "invite");
    }
    //!! this.$store.dispatch("loadCategories");
    console.log("Begin User Check!");
    if (!this.$store.state.users.user.isLogged) {
      this.checkLogged();
      console.log(this.$store.state.users.user.isLogged);
      //console.log(this.$route.name);
    }

    //getting currency rates
    this.$store.dispatch("getCurrencyRates");

    //check cookie
    if(localStorage.sxCookie){
      this.$store.commit("setCookie", true);
    }else{  
      this.showCookie();
    }

    //^ time loop to check inbox
    this.pollData();
  },
  methods: {
    showCookie(){
      let position = "is-bottom";
      if(this.isMobile){
        position = "is-top";
      }
      this.$buefy.snackbar.open({
        message: this.$t("cookie.message"),
        position: position,
        type: "is-info",
        actionText: this.$t("cookie.confirm"),
        indefinite: true,
        queue: false,
        onAction: () => {
          this.$store.commit("setCookie", true);
        },
        onClose: () => {
          this.$store.commit("setCookie", false);
        },
      });
      // this.$buefy.dialog.confirm({
      //   title: this.$t("cookie.title"),
      //   message: this.$t("cookie.message"),
      //   confirmText: this.$t("cookie.confirm"),
      //   cancelText: this.$t("cookie.cancel"),
      //   type: "is-info",
      //   hasIcon: true,
      //   onConfirm: () => {
      //     this.$store.commit("setCookie", true);
      //   },
      //   onCancel: () => {
      //     this.$store.commit("setCookie", false);
      //   },
      // });
    },
    handleBackButton() {
      console.log("back button pressed");
      if(this.$route.name == "HomeView" || this.$route.name == "CategoryView"){
        this.scrollHistory();
      }else{
        localStorage.removeItem("scrollY");
      }  
    },
    scrollHistory() {
      if(localStorage.getItem("scrollY")){
        let scrollY = localStorage.getItem("scrollY");
        localStorage.removeItem("scrollY");
        setTimeout(function () {
          window.scrollTo(0, scrollY);
        }, 300);
      } 
    },
    checkLogged: function () {
      if (localStorage.sxToken) {
        console.log("Validating Token..");
        console.log("Token:" + localStorage.sxToken);
        //begin post --------------------------------------------
        this.axios
          .post(`${this.$env.api}/users/validate.php`, {
            token: localStorage.sxToken,
          })
          .then((response) => {
            console.log("RESPONCE:" + JSON.stringify(response));
            if (response.data.success) {
              this.$store.commit("users/validateUser", response.data.data);
              this.$store.dispatch("favorites/loadFavorites");
              console.log("Loged!");
              console.log(response.data.data);
            }
          })
          .catch((error) => {
            //this.isLoading = false;
            console.log("token error ",error);
          });
        //end post-------------------------------------------------
      } else {
        console.log("Not Loged!");
      }
    },
    //pull data from server
    pollData() {
      this.polling = setInterval(() => {
        if (this.$store.state.users.user.isLogged) {
          console.log("Polling...");
          //this.countInbox();
          this.$store.dispatch("checkinbox/checkInbox");
        } else {
          console.log("Not Polling...");
        }
      }, 30000);
    },
    countInbox: function () {
      // this.axios
      //   .post(`${this.$env.api}/users/user_message.php`, {
      //     token: localStorage.GReviewToken,
      //     action: "countSigned",
      //   })
      //   .then((response) => {
      //     //console.log(response.data);
      //     if (response.data.success) {
      //       //console.log("Proofs:" + response.data.counted);
      //       this.$store.commit("setCount", response.data.counted);
      //     } else {
      //       console.log("Error getting data");
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     //this.errored = true;
      //   });
      // //.finally(() => (this.loading = false));
    },
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  created() {
    //this.countInbox();
   // this.pollData();
  },
};
</script>

<style lang="scss">
:root{
  --swiper-theme-color: #000000;
  --swiper-navigation-size: 44px;
  --swiper-navigation-top-offset: 50%;
  --swiper-navigation-sides-offset: 10px;
  --swiper-navigation-color: var(--swiper-theme-color);

  --swiper-navigation-size: 1.5rem;
}

#app{
  background-color: #f5f5f5;
}

//Import Fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;700&display=swap");
@import url("@egjs/vue-flicking/dist/flicking.css");
// Import Fontawesome
//@import "static/fontawesome/css/all.min.css";
@import "static/syntho/syntho-style.css";
@import "static/salex/style.css";
@import "static/social/style.css";
// Import Bulma's core
@import "~bulma/sass/utilities/_all";
// Set your colors
$primary: #97a3ae;
$success: #7cbc4b;
// $modal-background-background-color: #000;
$primary-invert: findColorInvert($primary);
//theme mod
$mydark: #23262b; //#00bb8f; //00D1B2
$mylight: #ebebeb; //#00bb8f; //00D1B2
// $dark: #0d4553;
$filter: #f4f4f4; //#00bb8f; //00D1B2
$sidebar-box-shadow: 0px;
$sidebar-width: 330px;
// $body-background-color: #4a4f54;
$content-heading-color: #000000;

$sxportable: #ffcd0b;
$sxmodular: #f37422;
$sxcustom: #ed3a25;
$sxgraphic: #71cee3;
$sxproject: #b393b0;

$sx-violet: #6E4FD7;
$sx-pink: #FF008D;
$sx-orange: #FFB000;
$sx-bluegreen: #00C793;

$sxgreen: #7cbc4b;
$sxgreen-light: #8dc457;
$sxgreen-dark: #669f42;
$sxgreen-dark2: #5f9340;

$sxgray: #4a4f54;
$sxgray2: #41464c;
$sxgray3: #383c41;
$sxgray4: #2e3136;
$sxgray5: #23262b;

$sxlight: #97a3ae;
$sxlight2: #d8dfe1;
$sxlight3: #f7f7f7;

// $navbar-item-color: $sxgray!important;
// $navbar-dropdown-arrow: $sxgray!important;

$footer-background-color: $sxgray5;
$footer-color: $sxlight2;
$footer-padding: 2rem 1.5rem 2rem;

// h1, h2, h3, h4, h5, h6 {
//     font-size: unset; 
//     font-weight: unset;
// }

//$family-primary: 'Roboto', sans-serif;
$family-primary: "Roboto", sans-serif;

$card-radius: 0.5rem;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black,
  ),
  "black": (
    $black,
    $white,
  ),
  "light": (
    $light,
    $light-invert,
  ),
  "dark": (
    $dark,
    $dark-invert,
  ),
  "primary": (
    $primary,
    $primary-invert,
  ),
  "info": (
    $info,
    $info-invert,
  ),
  "success": (
    $success,
    $success-invert,
  ),
  "warning": (
    $warning,
    $warning-invert,
  ),
  "danger": (
    $danger,
    $danger-invert,
  ),
  "mydark": (
    $mydark,
    $white,
  ),
  "mylight": (
    $mylight,
    $black,
  ),
  "filter": (
    $filter,
    $white,
  ),
  "sxportable": (
    $sxportable,
    $white,
  ),
  "sxmodular": (
    $sxmodular,
    $white,
  ),
  "sxcustom": (
    $sxcustom,
    $white,
  ),
  "sxgraphic": (
    $sxgraphic,
    $white,
  ),
  "sxproject": (
    $sxproject,
    $white,
  ),
  "sxgreen": (
    $sxgreen,
    $white,
  ),
  "sxgreen-light": (
    $sxgreen-light,
    $white,
  ),
  "sxgreen-dark": (
    $sxgreen-dark,
    $white,
  ),
  "sxgreen-dark2": (
    $sxgreen-dark2,
    $white,
  ),
  "sxgray": (
    $sxgray,
    $white,
  ),
  "sxgray2": (
    $sxgray2,
    $white,
  ),
  "sxgray3": (
    $sxgray3,
    $white,
  ),
  "sxgray4": (
    $sxgray4,
    $white,
  ),
  "sxgray5": (
    $sxgray5,
    $white,
  ),
  "sxlight": (
    $sxlight,
    $white,
  ),
  "sxlight2": (
    $sxlight2,
    $sxgray,
  ),
  "sxpink": (
    $sx-pink,
    $white,
  ),
  "sxviolet": (
    $sx-violet,
    $white,
  ),
  "sxorange": (
    $sx-orange,
    $black,
  ),
  "sxbluegreen": (
    $sx-bluegreen,
    $white,
  ),
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

$input-icon-color: $sxlight;
$input-icon-active-color: $sxgray;


$navbar-height: 4.25rem;

.navbar-item img {
    max-height: 2.75rem !important;
}

.navbar-item {
 // color: #b3bcc5 !important;
  // font-weight: 400 !important;
  // text-transform: uppercase;
  // font-size: 1.2rem;
}


.header-title {
  color: #fff;
  font-size: 4rem;
  font-family: "Bebas Neue";
}

.header-subtitle {
  color: #fff;
  font-size: 2rem;
}
//end nav icon

$card-header-background-color: $primary;

.card-header-image {
  background-color: #0f92ff !important;
}

.card-header-stats {
  background-color: #0f92ff !important;
}

// .card-header-actions{
//   background-color: #0f92ff!important;
// }

$card-header-color: $sxlight3;

$card-header-pasxing: 30px 20px;

// .card-header-title {
//   font-size: 2.3rem;
//   text-transform: uppercase;
//   font-weight: 100 !important;
// }

.modal-card-nofoot {
  border-radius: 0 0 5px 5px;
}

.card-footer {
  padding: 20px;
  flex-shrink: 0;
  justify-content: flex-end;
}

.card-footer-sample {
  padding: 0px !important;
  flex-shrink: unset;
  justify-content: unset;
}

.card-footer .button:not(:last-child) {
  margin-right: 0.5em;
}

.modal-card-title {
  font-family: "Roboto", sans-serif;
  // text-transform: uppercase;
  // font-weight: 600 !important;
}

.main {
  padding-top: calc(4.25rem + 40px);
  min-height: calc(100vh - 128px);
}


.link-bt {
  cursor: pointer;
}

.link-red {
  color: #ed3a25;
}

.link-green {
  color: #7cbc4b;
}


.ico-bt {
  all: unset;
  cursor: pointer;
  font-size: 20px;
}

.ico-bt-lg {
  all: unset;
  cursor: pointer;
  font-size: 1.8rem;
  margin-right: 15px;
}

.ico-red {
  color: #f14668;
}
.ico-green {
  color: #7cbc4b;
}
.ico-blue {
  color: #3e8ed0;
}
.ico-orange {
  color: #ffa600;
}
.ico-gray {
  color: #4a4f54;
}
.ico-light {
  color: #cfcfcf;
}

.red-bt,
.green-bt,
.oranj-bt,
.blue-bt,
.light-bt {
  color: #c9c9c9;
  cursor: pointer;
  float: left;
}

.red-bt:hover {
  color: #f14668;
}

.green-bt:hover {
  color: #7cbc4b;
}

.blue-bt:hover {
  color: #3e8ed0;
}

.oranj-bt:hover {
  color: #ffa600;
}

.light-bt:hover{
  color: #ffffff;
}

.green-bt-active {
  color: #7cbc4b;
}

.red-bt.active {
  color: #f14668;
}


.table {
  background-color: transparent !important;
}

.icon {
  //margin: 5px 5px 0px 0px;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

// .grid:before {
//   content: "" !important;
// }

// .image:before {
//   content: "" !important;
// }

// .check:before {
//   content: "" !important;
// }

.not-clickable {
  pointer-events: none;
}

.not-selectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.clickable {
  cursor: pointer;
}

.menu-list a {
  font-family: "Roboto", sans-serif;
  // text-transform: uppercase;
  font-size: .9em !important;
}

//fixes
.tag::before{
  content: none !important;
}

.label{
 text-transform: capitalize !important; 
}

.sx-block-title{
  color: hsl(0deg, 0%, 21%);
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.5rem;
  line-height: 1;
}

//user account styles
.sx-section-title-wrapper {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  justify-content: space-between;
}

.sx-section-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.sx-horizonal-line {
    border-right: 1px solid #e6e6e6;
}

.dashboard-box {
  color: #7a7a7a;
  cursor: pointer;
}


//user dashboard styles
.dashboard-box:hover .el-color {
  color: #fff;
}

.box-bt {
  text-align: center;
  color: #4a4f54 !important;
  font-size: 1.5rem;
  font-weight: 300;
  cursor: pointer;
}

.box-bt:hover {
  color: #ffffff !important;
  background-color: #7cbc4b;
}

.box-green .el-color {
  color: #7cbc4b;
}

.box-green:hover {
  background-color: #7cbc4b;
  color: #fff;
}

.box-red .el-color {
  color: #f14668;
}

.box-red:hover {
  background-color: #f14668;
  color: #fff;
}

.box-orange .el-color {
  color: #ffa600;
}

.box-orange:hover {
  background-color: #ffa600;
  color: #fff;
}

.box-blue .el-color {
  color: #3e8ed0;
}

.box-blue:hover {
  background-color: #3e8ed0;
  color: #fff;
}

.box-gray .el-color {
  color: #4a4f54;
}

.box-gray:hover {
  background-color: #4a4f54;
  color: #fff;
}



.status-ico{
  float: left;
  font-size: x-large;
  opacity: 0.5;
}

.status-ico:hover{
  opacity: 1;
}

.ico-red {
  color: #f14668;
}
.ico-green {
  color: #7cbc4b;
}
.ico-blue {
  color: #3e8ed0;
}
.ico-orange {
  color: #ffa600;
}
.ico-gray {
  color: #4a4f54;
}


.leaflet-top, .leaflet-bottom {
  z-index: 39 !important;
}

.leaflet-pane{
  z-index: 38 !important;
}

.leaflet-control-attribution{
  display:none !important;
}
.my-div-icon{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.my-icon{
  width: 30px;
  height: 42px;
}

.map-image{
  width: 100% !important;
  border-radius: 10px;
  max-width: 100% !important;
}

.map-info{
  background-color: #fff;
  border-radius: 10px;
  padding: 2px 4px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  width: 80px;
  // transform: translate(-25%, 0);
  transition: all 0.3s ease-in-out;
}

// .map-info:hover{
//     // transform: translate(-35%, 0);
//     padding: 5px 10px;
//     width: 180px;
// }

.map-span{
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  white-space: nowrap;
}

.code-input input{
  width: 50px !important;
}

/* Chrome, Safari, Edge, Opera */
.code-input input::-webkit-outer-spin-button,
.code-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.code-input input[type=number] {
  -moz-appearance: textfield;
}



.noselect{
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.grid-list-bt{
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}


.advers-list-table{
  position: relative;
  z-index: 1;
}

.advetrs-grid{
  display: grid;
  /* grid-template-columns: repeat(6, 1fr); */
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  position: relative;
  z-index: 1;
}


.icon.is-large {
    font-size: 3rem !important;
}


.leaflet-control-custom-button{
  padding-top: 2px;
  font-size: 1.2rem !important;
}


.no-drag{
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.swiper-button-prev, .swiper-button-next{
  background-color: #ffffff;
  width: 50px;
  height: 50px;
  font-weight: 900;
}

.swiper-button-next{
  right: -10px;
  border-radius: 50% 0 0 50%;
  box-shadow: inset 3px 0px 6px 0px rgb(193 193 193 / 40%);
  border: 1px solid #ffffff;
}
.swiper-button-prev{
  left: -10px;
  border-radius: 0 50% 50% 0;
  box-shadow: inset -3px 0px 6px 0px rgb(193 193 193 / 40%);
  border: 1px solid #ffffff;
}

.swiper-button-next:hover {
  background-color: #f1f1f1; /* darker on hover */
  background: linear-gradient(90deg, rgba(241,241,241,1) 0%, rgba(255,255,255,1) 100%);

}
.swiper-button-prev:hover {
  background-color: #f1f1f1; /* darker on hover */
  background: linear-gradient(-90deg, rgba(241,241,241,1) 0%, rgba(255,255,255,1) 100%);
}

.top-carusel .swiper .swiper-button-prev, .top-carusel .swiper .swiper-button-next{
  top: 30% !important;
}


//mobile styles iphone

// @media screen and (max-width: 1024px){
//   .advetrs-grid{
//     padding: 0 10px !important;
//   }
// }


//hide on mobile
@media screen and (max-width: 1023px){
  .hide-on-mobile{
    display: none !important;
  }
}

//hide on desktop
@media screen and (min-width: 1024px){
  .hide-on-desktop{
    display: none !important;
  }
}

//main padding on mobile
@media screen and (max-width: 1024px){
  .main{
    // padding-top: calc(4.25rem + 40px) !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .b-sidebar .sidebar-content{
    z-index: 2147483647 !important;
  }
}



// @media screen and (max-width: 800px){
//   .products-columns{
//     margin-right: 0px !important;
//     margin-left: 0px !important;
//   }
//   .advert-item{
//     padding: 0 10px !important;
//   }
// }


// @media screen and (max-width: 450px){
//   .products-columns{
//     margin-right: 0px !important;
//     margin-left: 0px !important;
//   }
//   .products-pagination{
//     padding: 10px !important;
//   }
// }




// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
// @import "~bulma-prefers-dark/bulma-prefers-dark.sass"; ! this is a potential solutionn for dark mode
//Uses @media (prefers-color-scheme: dark) to detect dark mode
</style>
