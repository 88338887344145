<template>
  <nav
    class="navbar is-fixed-top shadow"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="nav-container">
      <div class="navbar-brand hide-on-desktop" style="flex-grow: 1; justify-content: center; align-items: center">
        <router-link to="/" class="navbar-item">
          <img src="@/assets/sx_logo.svg" alt="" width="100px" height="80px" />
        </router-link>
        <b-field class="is-100 mr-2">
            <Search />
          </b-field>
      </div>
      <div class="navbar-brand hide-on-mobile">
        <!-- <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a> -->
        <div class="navbar-item clickable" @click="pressLogo()">
          <img src="@/assets/sx_logo.svg" alt="" width="100px" height="80px" />
        </div>
      </div>
      <div class="navbar-menu">
        <div class="navbar-start">
          <b-navbar-dropdown :label="getLang(currentLang)" :arrowless="true">
                <b-navbar-item @click="langSwitch('ro')">
                    Ro
                </b-navbar-item>
                <b-navbar-item @click="langSwitch('ru')">
                    Ру
                </b-navbar-item>
                <b-navbar-item @click="langSwitch('en')">
                    En
                </b-navbar-item>
            </b-navbar-dropdown>
            <b-navbar-item tag="div">
              <b-button type="is-primary" @click="isMenu = !isMenu">{{$t("Categories")}}</b-button>
            </b-navbar-item>
          <!-- <div class="navbar-item has-dropdown">
            <a class="navbar-link"> Docs </a>
            <div class="navbar-dropdown">
              <a class="navbar-item"> Overview </a>
              <a class="navbar-item"> Elements </a>
              <a class="navbar-item"> Components </a>
              <hr class="navbar-divider" />
              <div class="navbar-item">Version 0.9.4</div>
            </div>
          </div> -->
        </div>
        <div
          class="navbar-start"
          style="flex-grow: 1; justify-content: center; align-items: center"
        >
          <b-field class="is-100 mr-2">
            <Search />
          </b-field>
        </div>
        <div class="navbar-end">
          <b-navbar-item @click="openModal('exchange')" tag="div" class="clickable widget-icon">
              <b-icon icon="synho-social currency-exchange"></b-icon>
            </b-navbar-item>
            <b-navbar-item v-if="user.isLogged" tag="router-link" :to="{ path: '/my-messages' }" class="clickable widget-icon">
              <b-icon icon="synho envelope"></b-icon> <div class="nav-badge" v-if="messages > 0"></div>
            </b-navbar-item>
            <b-navbar-item v-if="user.isLogged" tag="router-link" :to="{ path: '/my-notifications' }" class="clickable widget-icon">
              <b-icon icon="synho bell"></b-icon><div class="nav-badge" v-if="notifications > 0" ></div>
            </b-navbar-item>
          <b-navbar-item v-if="!user.isLogged" @click="openModal('login')">
            {{ $t("Login") }}
          </b-navbar-item>
          <b-navbar-dropdown :label="$t('Account')" v-if="user.isLogged">
              <b-navbar-item tag="router-link" :to="{ path: '/my-dashboard' }">
                <b-icon icon="syntho grid-2" class="mr-1"></b-icon> {{$t('Dashboard')}}
              </b-navbar-item>
              <b-navbar-item tag="router-link" :to="{ path: '/my-adverts' }">
                <b-icon icon="syntho file-image" class="mr-1"></b-icon> {{$t('Adverts')}}
              </b-navbar-item>
              <b-navbar-item tag="router-link" :to="{ path: '/my-favorites' }">
                <b-icon icon="syntho heart" class="mr-1"></b-icon> {{$t('Favorites')}}
              </b-navbar-item>
              <b-navbar-item tag="router-link" :to="{ path: '/my-messages' }">
                <b-icon icon="syntho envelope" class="mr-1"></b-icon> {{$t('Messages')}}
              </b-navbar-item>
              <b-navbar-item tag="router-link" :to="{ path: '/my-profile' }">
                <b-icon icon="syntho user" class="mr-1"></b-icon> {{$t('My Profile')}}
              </b-navbar-item>
              <b-navbar-item @click="logout()">
                <b-icon icon="syntho arrow-right-from-bracket" class="mr-1"></b-icon> {{$t('Logout')}}
              </b-navbar-item>
          </b-navbar-dropdown>
          <b-navbar-item tag="div">
            <b-button @click="$router.push({ path: '/add-advert' })" type="is-success" :label="$t('postAd')"/>
          </b-navbar-item>
          </div>
      </div>
    </div>
  </nav>
  <!-- <b-navbar
    class="shadow"
    wrapper-class="container"
    :mobile-burger="false"
    :fixed-top="true"
    type="is-white"
  >
    <template #brand>
      <b-navbar-item
        class="is-sxlight3"
        tag="router-link"
        :to="{ path: '/' }"
      >
     
        <img src="@/assets/sx_logo.svg" alt="" width="100px" height="80px"/>
      </b-navbar-item>
    </template>
    <template #start>
              <b-navbar-dropdown :label="getLang(currentLang)">
                <b-navbar-item @click="langSwitch('ro')">
                    Rom
                </b-navbar-item>
                <b-navbar-item @click="langSwitch('ru')">
                    Рус
                </b-navbar-item>
                <b-navbar-item @click="langSwitch('en')">
                    Eng
                </b-navbar-item>
            </b-navbar-dropdown>
            <b-navbar-item @click="openModal('exchange')" tag="div" class="clickable widget-icon">
              <b-icon icon="synho-social currency-exchange"></b-icon>
            </b-navbar-item>
            <b-navbar-item v-if="user.isLogged" tag="router-link" :to="{ path: '/my-messages' }" class="clickable widget-icon">
              <b-icon icon="synho envelope"></b-icon> <div class="nav-badge" v-if="messages > 0"></div>
            </b-navbar-item>
            <b-navbar-item v-if="user.isLogged" tag="router-link" :to="{ path: '/my-notifications' }" class="clickable widget-icon">
              <b-icon icon="synho bell"></b-icon><div class="nav-badge" v-if="notifications > 0" ></div>
            </b-navbar-item>
            <b-navbar-item tag="div">
              <b-button type="is-light" @click="isMenu = !isMenu">{{$t("Categories")}}</b-button>
            </b-navbar-item>
          <b-navbar-item tag="div">
            <b-field>
              <b-input placeholder="Search..."
                  type="search"
                  icon="magnifying-glass" expanded>
              </b-input>
              <p class="control">
                  <b-button type="is-primary" label="Search" />
              </p>
            </b-field>
          </b-navbar-item>
    </template>
 
    <div>center</div>
  
    <template #end>
      <b-navbar-item v-if="!user.isLogged" @click="openModal('login')">
        {{ $t("Login") }}
      </b-navbar-item>
      <b-navbar-dropdown :label="$t('Account')" v-if="user.isLogged">
          <b-navbar-item tag="router-link" :to="{ path: '/my-dashboard' }">
            <b-icon icon="syntho grid-2" class="mr-1"></b-icon> {{$t('Dashboard')}}
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ path: '/my-adverts' }">
            <b-icon icon="syntho file-image" class="mr-1"></b-icon> {{$t('Adverts')}}
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ path: '/my-favorites' }">
            <b-icon icon="syntho heart" class="mr-1"></b-icon> {{$t('Favorites')}}
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ path: '/my-messages' }">
            <b-icon icon="syntho envelope" class="mr-1"></b-icon> {{$t('Messages')}}
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ path: '/my-profile' }">
            <b-icon icon="syntho user" class="mr-1"></b-icon> {{$t('My Profile')}}
          </b-navbar-item>
          <b-navbar-item @click="logout()">
            <b-icon icon="syntho arrow-right-from-bracket" class="mr-1"></b-icon> {{$t('Logout')}}
          </b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-item tag="div">
        <b-button @click="$router.push({ path: '/add-advert' })" type="is-success" :label="$t('postAd')"/>
      </b-navbar-item>
    </template>
  </b-navbar> -->
</template>

<style scoped>

.nav-container {
    align-items: stretch;
    display: flex;
    min-height: 4.25rem;
    width: 100%;
}
.shadow {
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.17);
}
.widget-icon {
  font-size: 1.4rem;
  color: #97a3ae !important;
}
.widget-icon:hover {
  color: #6e767e !important;
}
.nav-badge {
  position: absolute;
  top: 20px;
  right: 8px;
  background: #ff3860;
  border-radius: 50%;
  min-width: 10px;
  min-height: 10px;
}

.is-100 {
  width: 100%;
  position: relative;
}

@media screen and (min-width: 1400px) {
  .is-100 {
    width: 100%;
  }
  /* .is-sxlight3{
    font-size: 1.2rem;
  }
  .nav-badge{
    top: 10px;
    right: 5px;
  } */
}
</style>

<script>
import { mapState } from "vuex";
import modalsMixin from "@/mixins/modalsMixin";
import Search from "@/components/navigation/Search.vue";

export default {
  name: "Navbar",
  mixins: [modalsMixin],
  components: {
    Search,
  },
  data() {
    return {
      // currentLang: "Rom",
      didScroll: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollPage);
  },
  computed: {
    ...mapState("users", ["user"]),
    ...mapState(["lang"]),
    ...mapState("checkinbox", ["messages", "notifications"]),
    isMenu: {
      get() {
        return this.$store.state.showMenu;
      },
      set(val) {
        this.$store.commit("setMenu", val);
      },
    },
    //get & set lang
    currentLang: {
      get() {
        return this.$store.state.lang;
      },
      set(lang) {
        this.$store.commit("setLang", lang);
      },
    },
  },
  methods: {
    goTo(path) {
      this.$router.push({ path: path }).catch((err) => {
        console.log(err);
      });
    },
    pressLogo() {
      //if is on home page, scroll to top
      if (this.$route.path === "/") {
        // window.scrollTo(0, 0);
        //reload page
        this.$router.go();
      } else {
        this.$router.push({ path: "/" }).catch((err) => {
          console.log(err);
        });
      }
    },
    openModal(modal) {
      this.$store.commit("modals/showModal", modal);
    },
    logout() {
      this.$store.commit("users/logoutUser");
      this.$router.push({ path: "/" }).catch((err) => {
        console.log(err);
      });
    },
    langSwitch(lang) {
      console.log(lang);
      this.currentLang = lang;
      this.$i18n.locale = lang;
      //this.$store.commit("setLang", lang);
      // this.$i18n.locale = lang;
    },
    getLang(lang) {
      let langNames = {
        ro: "Ro",
        ru: "Ру",
        en: "En",
      };
      return langNames[lang] || "En";
    },
    scrollPage() {
      //this.didScroll = true;
      if (window.scrollY > 200) {
        this.didScroll = true;
      } else {
        this.didScroll = false;
      }
    },
  },
};
</script>
