<template>
  <section>
    <b-modal :active.sync="isActive" has-modal-card trap-focus aria-role="dialog" aria-modal :can-cancel="false">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">{{$t('Change Password')}}</p>
          <button type="button" class="delete" @click="closeModal()" />
        </header>
        <section class="modal-card-body">
          <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
          <b-field v-if="form.visible" :label="$t('Current Password')" :type="form.oldPass.status" :message="form.oldPass.message">
            <b-input type="password" v-model="form.oldPass.data" required></b-input>
          </b-field>
          <b-field v-if="form.visible" :label="$t('New Password')" :type="form.newPass.status" :message="form.newPass.message">
            <b-input type="password" v-model="form.newPass.data" required></b-input>
          </b-field>
          <b-field class="m-t-md" v-if="form.visible" :label="this.form.human.label" :type="form.human.status"
            :message="form.human.message" horizontal>
            <b-input type="text" v-model="form.human.data" :placeholder="$t('Your answer')" required></b-input>
          </b-field>

          <b-message :type="form.status" v-if="form.result">
            {{ $t(form.message) }}
          </b-message>
        </section>
        <footer class="modal-card-foot" v-if="form.visible">
          <button class="button" type="button" @click="closeModal()">
            {{$t('Cancel')}}
          </button>
          <button  class="button is-success" @click="onSubmit()" :loading="isLoading">
            {{$t('Change Password')}}
          </button>
        </footer>
        <footer class="modal-card-foot" v-if="!form.visible">
          <button class="button" type="button" @click="closeModal()">
            {{$t('Close')}}
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  name: "ChangePasswordModal",
  data() {
    return {
      isLoading: false,
      countErrors: 0,
      form: {
        oldPass: {
          data: "",
          status: "",
          message: ""
        },
        newPass: {
          data: "",
          status: "",
          message: ""
        },
        human: {
          lable: "",
          summ: null,
          data: null,
          status: "",
          message: ""
        },
        visible: true,
        message: "",
        result: false,
        status: ""
      }
    };
  },
  methods: {
    generateCapcha() {
      let var0 = Math.floor(Math.random() * 9) + 1;
      let var1 = Math.floor(Math.random() * 9) + 1;
      this.form.human.summ = var0 + var1;
      this.form.human.label = var0 + '+' + var1 + '=';
    },
    onSubmit() {
      //evt.preventDefault();
      //this.$store.commit("DO_LOGIN", true);
      //this.$store.commit("SHOW_MODAL", false);

      if (
        this.formCheck("oldPass") & this.formCheck("newPass") &
        this.humanCheck()
      ) {
        //this.form.visible = false;
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post("users/manage_account.php", {
            action: "updatePass",
            newPass: this.form.newPass.data,
            oldPass: this.form.oldPass.data,
            token: localStorage.sxToken,
          })
          .then(response => {
            this.isLoading = false;
            this.form.result = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
              this.form.visible = false;
              //this.closeModal();
            } else {
              this.form.status = "is-danger";
              // this.hasError();
              //this.form.countErrors++;
              if (response.data.type == "oldPass") {
                this.form.oldPass.status = "is-danger";
              } else {
                this.form.oldPass.status = "is-danger";
                this.form.newPass.status = "is-danger";
              }
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error);
            //this.hasError();
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },
    formCheck(field) {
      if (this.form[field].data.length > 0) {
        this.form[field].status = "is-success";
        return true;
      } else {
        this.form[field].status = "is-danger";
        this.form[field].message = "This field is required.";
        return false;
      }
    },
    humanCheck() {
      if (this.form.human.data == this.form.human.summ) {
        this.form.human.status = "is-success";
        this.form.human.message = "Human, eh?";
        return true;
      } else {
        this.form.human.status = "is-danger";
        this.form.human.message = this.$t("You did not pass the human test.");
        return false;
      }
    },
    resetForm() {
      this.form.newPass.data = "";
      this.form.newPass.status = "";
      this.form.newPass.message = "";
      this.form.oldPass.data = "";
      this.form.oldPass.status = "";
      this.form.oldPass.message = "";
      this.form.human.data = null;
      this.form.human.status = "";
      this.form.human.message = "";
      this.form.message = "";
      this.form.result = false;
      this.form.status = "";
      this.form.visible = true;
    },
    closeModal() {
      this.resetForm();
      this.$store.commit("modals/hideModal", "password");
    },
  },
  computed: {
    isActive: {
      get: function () {
        return this.$store.state.modals.password;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "password");
        return false;
      }
    }
  },
  watch: {
    isActive: function (val, oldVal) {
      if (val) {
        this.generateCapcha();
      }
      this.resetForm();
    }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between;
  /* flex-end; */
}
</style>
