<template>
  <section>
    <b-modal :active.sync="isActive" has-modal-card trap-focus aria-role="dialog" aria-modal :can-cancel="false">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">{{$t('Create a new account')}}</p>
          <button type="button" class="delete" @click="closeModal()" />
        </header>
        <section class="modal-card-body">
          <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>


          <div v-if="step==1">
            <form autocomplete="off" v-if="step==1" v-on:submit.prevent="submitForm()">

              <b-field :label="$t('Account Type')">
              <b-radio-button v-model="form.accountType.data" 
                  native-value="0"
                  type="is-success is-light" expanded>
                  <b-icon icon="user"></b-icon>
                  <span>{{$t('Personal')}}</span>
              </b-radio-button>
    
              <b-radio-button v-model="form.accountType.data"
                  native-value="1"
                  type="is-info is-light" expanded>
                  <b-icon icon="users"></b-icon>
                  <span>{{$t('Company')}}</span>
              </b-radio-button>
          </b-field>


            <b-field :label="$t('Username')" :type="form.username.status" :message="form.username.message">
              <b-input type="text" v-model="form.username.data" :placeholder="$t('Your Username')" expanded :loading="checkingUsername"></b-input>
              <!-- <p class="control">
                <b-tooltip label="Public Information" position="is-left">
                  <b-button class="button is-static" icon-left="circle-question"></b-button>
                </b-tooltip>
              </p> -->
            </b-field>

              <b-field :label="$t('Phone')" :type="form.phone.status" :message="form.phone.message">
                <p class="control">
                  <span class="button is-static">+373</span>
                </p>
                <b-input type="phone" v-model="form.phone.data" :placeholder="$t('Your Phone')" expanded></b-input>
              </b-field>


              <b-field :label="$t('Password')" :type="form.pass.status" :message="form.pass.message">
                <b-input autocomplete="off" type="password" v-model="form.pass.data" :placeholder="$t('Your Password')" expanded></b-input>
              </b-field>

            </form>
          </div>



        <div v-if="step==2">
          <div class="mb-2" ><strong>{{$t('Verification code')}}:</strong></div>
          <form autocomplete="off" v-on:submit.prevent="checkCode()">
            <b-field grouped>
              <b-field class="code-input">
                  <b-input ref="input1" size="is-large" @input="fucusNext('input2')" v-model="codeInput.code1" autocomplete="off" :disabled="isCheckingCode"></b-input>
                  <b-input ref="input2" size="is-large" @input="fucusNext('input3')" v-model="codeInput.code2" maxlength="1" :has-counter="false" autocomplete="off" :disabled="isCheckingCode"></b-input>
                  <b-input ref="input3" size="is-large" @input="fucusNext('input4')" v-model="codeInput.code3" maxlength="1" :has-counter="false" autocomplete="off" :disabled="isCheckingCode"></b-input>
                  <b-input ref="input4" size="is-large" @input="fucusNext('input5')" v-model="codeInput.code4" maxlength="1" :has-counter="false" autocomplete="off" :disabled="isCheckingCode"></b-input>
                  <b-input ref="input5" size="is-large" @input="checkCode()" v-model="codeInput.code5" maxlength="1" :has-counter="false" autocomplete="off" :disabled="isCheckingCode"></b-input>
              </b-field>
            </b-field>
          </form>
        </div>


          <b-message class="mt-2" :type="form.status" v-if="form.result">
            {{ $t(form.message) }}
          </b-message>

          <b-button class="my-2" @click="tryNewPhone()" v-if="step==2 && wrongCode" expanded type="is-light">{{$t('Try Another Phone Number')}}</b-button>

        </section>
        <footer class="modal-card-foot">
          <b-button type="is-light" @click="closeModal()">
            {{ $t('Cancel') }}
          </b-button>
          <b-button v-if="step==1" type="is-info" @click="submitForm()">
            {{ $t('Continue') }}
          </b-button>
          <b-button v-if="step==2" type="is-info" @click="resendCode()" :disabled="countBack > 0">
            {{ $t('Resend Code') }} <span v-if="countBack > 0">&nbsp;({{countBack}})</span>
          </b-button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import formCheck from "@/mixins/formCheck";

export default {
  name: "RegisterPhoneModal",
  mixins: [formCheck],
  data() {
    return {
      step: 1,
      isLoading: false,
      countErrors: 0,
      newEmail: "",
      isCheckingCode: false,
      wrongCode: false,
      //codeIsValid: false,
      countBack: 30,
      codeInput:{
        code1: "",
        code2: "",
        code3: "",
        code4: "",
        code5: ""
      },
      checkingUsername: false,
      usernameValid: false,
      form: {
        phone: {
          data: "",
          status: "",
          message: ""
        },
        username: {
          data: "",
          status: "",
          message: ""
        },
        pass: {
          data: "",
          status: "",
          message: ""
        },
        accountType: {
          data: "0",
          status: "",
          message: ""
        },    
        visible: true,
        message: "",
        result: false,
        status: ""
      },
    };
  },
  methods: {

    checkUsername() {
      return new Promise((resolve, reject) => {
        if (this.usernameCheck("username")) {
          this.checkingUsername = true;
          this.form.username.message = null;
          this.form.username.status = null;

          this.axios
            .post("libs/check_avalible.php", {
              username: this.form.username.data,
              action: "checkUsername",
              token: this.$store.state.tempToken,
            })
            .then(response => {
              this.checkingUsername = false;
              console.log("checkUsername:", response.data);
              if (response.data.success) {
                resolve(true);
              } else {
                this.usernameValid = false;
                this.form.username.message = response.data.message;
                this.form.username.status = "is-danger";
                resolve(false);
              }
            })
            .catch(error => {
              this.checkingUsername = false;
              console.log(error);
              reject(false);
            });
        } else {
          this.form.status = "is-danger";
          this.form.result = true;
          this.form.message = "Ooops! Something went wrong. Please try again.";
          resolve(false);
        }
      });
    },

    checkPhone() {
      return new Promise((resolve, reject) => {
        if (this.formCheck("phone")) {
          this.axios
            .post("libs/check_avalible.php", {
              phone: this.form.phone.data,
              action: "checkPhone",
              token: this.$store.state.tempToken,
            })
            .then(response => {
              this.isLoading = false;
              console.log("checkPhone:", response.data);
              if (response.data.success) {
                resolve(true);
              } else {
                this.form.phone.message = response.data.message;
                this.form.phone.status = "is-danger";
                resolve(false);
              }
            })
            .catch(error => {
              this.isLoading = false;
              console.log(error);
              reject(false);
            });
        } else {
          this.form.status = "is-danger";
          this.form.result = true;
          this.form.message = "Ooops! Something went wrong. Please try again.";
          resolve(false);
        }
      });
    },

    setCounter(){
      this.countBack = 30;
      this.countBack--;
      let interval = setInterval(() => {
        this.countBack--;
        if(this.countBack == 0){
          clearInterval(interval);
        }
      }, 1000);
    },

    tryNewPhone(){
      this.step = 1;
      //========================//
      this.wrongCode = false;
      this.isCheckingCode = false;
      this.phone.message = "";
      this.resetCode();
      this.clearResponce();
    },

    fucusNext(nextInputRef) {
      console.log(nextInputRef);
      if (nextInputRef == "input2") {
        console.log(this.codeInput.code1);
        if (this.codeInput.code1.length >= 5) {
          console.log("full code");
          let fullCode = this.codeInput.code1.trim();
          this.codeInput.code1 = fullCode.substring(0, 1);
          this.codeInput.code2 = fullCode.substring(1, 2);
          this.codeInput.code3 = fullCode.substring(2, 3);
          this.codeInput.code4 = fullCode.substring(3, 4);
          this.codeInput.code5 = fullCode.substring(4, 5);// 12376 
          this.checkCode();
          return;
        }
      }
      this.$nextTick(() => {
        this.$refs[nextInputRef].focus();
      });
    },

    clearCode(){
      this.codeInput.code1 = "";
      this.codeInput.code2 = "";
      this.codeInput.code3 = "";
      this.codeInput.code4 = "";
      this.codeInput.code5 = "";
    },

    resendCode(){
      this.sendCode();
    },

    sendCode(){
        this.setCounter();
        this.isLoading = true;
      //begin post -----------------------------------------------
        this.axios
          .post("libs/verify_phone.php", {
            phone: "+373"+this.form.phone.data,
            action: "sendCode",
            token: this.$store.state.tempToken,
          })
          .then(response => {
            this.isLoading = false;
            this.form.phone.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.step = 2;
              this.$nextTick(() => {
                this.$refs["input1"].focus();
              });
            } else {
              this.form.phone.status = "is-danger";
              this.form.message = "Ooops! Could not send verification code. Please try again.";
              this.form.result = true;
              this.form.status = "is-danger";
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error);
            //this.hasError();
          });
        //end post-------------------------------------------------
    },


    submitForm() {
      this.isLoading = true;
      this.form.message = "";
      this.form.result = false;
      this.form.status = "";

      Promise.all([this.checkUsername(), this.checkPhone()]).then((results) => {
        if (results[0] && results[1] && this.passCheck()) {
          this.sendCode();
        } else {
          this.form.status = "is-danger";
          this.form.result = true;
          this.form.message = "Ooops! Something went wrong. Please try again.";
          this.isLoading = false;
        }
      }).catch(() => {
        this.isLoading = false;
        console.log('An error occurred');
      });
    },


    checkCode() {
      this.isCheckingCode = true;
      this.isLoading = true;
      let code = "" + this.codeInput.code1 + this.codeInput.code2 + this.codeInput.code3 + this.codeInput.code4 + this.codeInput.code5;
      //begin post --------------------------------------------
      this.axios
        .post("libs/verify_phone.php", {
          phone: "+373"+this.form.phone.data,
          code: code,
          action: "checkCode",
          token: localStorage.sxToken,//this.$store.state.tempToken
        })
        .then(response => {
          this.isLoading = false;
          console.log(response.data);
          if (response.data.success) {
            this.isCheckingCode = false;
            this.codeIsValid = true;
            this.step = 3;
            this.form.message = "Registering User...";
            this.form.status = "is-info";
            this.form.result = true;
            this.wrongCode = false;
            this.registerUser();
          } else {
            this.form.message = response.data.message;
            this.form.status = "is-danger";
            this.form.result = true;
            this.wrongCode = true;
            this.resetCode();
          }
        })
        .catch(error => {
          this.isLoading = false;
          this.isCheckingCode = false;
          console.log(error);
          //this.hasError();
          });
      // //end post-------------------------------------------------   
    },
    registerUser() {
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post("users/register.php", {
            action: "register",
            username: this.form.username.data,
            phone: this.form.phone.data,
            password: this.form.pass.data,
            accountType: this.form.accountType.data,
            token: this.$store.state.tempToken,
          })
          .then(response => {
            this.isLoading = false;
            this.form.result = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
            } else {
              this.form.status = "is-danger";        
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error);
            //this.hasError();
          });
        //end post-------------------------------------------------
     },
    resetCode(){
      this.codeInput.code1 = "";
      this.codeInput.code2 = "";
      this.codeInput.code3 = "";
      this.codeInput.code4 = "";
      this.codeInput.code5 = "";
      if(this.isCheckingCode){
        this.isCheckingCode = false;
        this.$refs["input1"].focus();
      }
    },
    resetForm(){
      this.step = 1;
      this.form.username.data = "";
      this.form.username.status = "";
      this.form.username.message = "";

      this.form.phone.data = "";
      this.form.phone.status = "";
      this.form.phone.message = "";

      this.form.pass.data = "";
      this.form.pass.status = "";
      this.form.pass.message = "";

      this.form.accountType.data = "0";

      this.form.message = "";
      this.form.result = false;
      this.form.status = "";
    },
    clearResponce() {
      this.form.message = "";
      this.form.result = false;
      this.form.status = "";
    },
    closeModal() {
      this.resetForm();
      this.resetCode();
      this.$store.commit("modals/hideModal", "registerPhone");
    },
  },
  computed: {
    isActive: {
      get: function () {
        return this.$store.state.modals.registerPhone;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "registerPhone");
        return false;
      }
    }
  },
  watch: {
    isActive: function (val) {
      if (val) {
        this.resetForm();
        this.resetCode();
      }
    }
  },
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between;
  /* flex-end; */
}
</style>
