<template>
    <div v-click-outside="hideMenu" class="mega-menu container" v-if="isMenu">
      <div class="columns">
        <div class="column is-2">
          <div class="mega-link" v-for="cat in categories" @mouseover="setCategoryData(cat)"  @click="goTo(cat.slug)">
            <div class="mega-link-text">{{cat[labels[lang]]}}</div>
          </div>
        </div>
        <div class="column">
         <div class="columns">
            <div class="column is-3 group-devider" v-for="(group, index) in currentCategoryData.groups" v-if="index < 4">
              <div class="group-names mb-3">{{group[labels[lang]]}}</div>
                <div v-for="subcat in group.subcategories">
                  <div class="mega-sublinks" @click="goTo(currentCategoryData.slug+'/'+subcat.slug)">{{subcat[labels[lang]]}}</div>
                </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-3 group-devider" v-for="(group, index) in currentCategoryData.groups" v-if="index >= 4 && index < 8">
              <div class="group-names mb-3">{{group[labels[lang]]}}</div>
                <div v-for="subcat in group.subcategories">
                  <div class="mega-sublinks" @click="goTo(currentCategoryData.slug+'/'+subcat.slug)">{{subcat[labels[lang]]}}</div>
                </div>
            </div>
         </div>
         <div class="columns">
            <div class="column is-3 group-devider" v-for="(group, index) in currentCategoryData.groups" v-if="index >= 8 && index < 12">
              <div class="group-names mb-3">{{group[labels[lang]]}}</div>
                <div v-for="subcat in group.subcategories">
                  <div class="mega-sublinks" @click="goTo(currentCategoryData.slug+'/'+subcat.slug)">{{subcat[labels[lang]]}}</div>
                </div>
            </div>
         </div>
        </div>
      </div>
   </div>
</template>

<style scoped>

</style>

<script>
import { mapState } from "vuex";
import translationHelper from "@/mixins/translationHelper";

export default {
  name: "MegaMenu",
  mixins: [translationHelper],
  data() {
    return {
      type: "Sell",
      location: "Chisinau",
      popupItem: null,
      didOpen: false,
      currentCategoryData: {},
    };
  },
  computed: {
    ...mapState(["lang", "categories"]),
    isMenu:{
      get(){
        return this.$store.state.showMenu;
      },
      set(val){
        this.$store.commit("setMenu", val);
      }
    },
    // isMobile() {
    //   return this.$store.state.isMobile;
    // },
  },
  mounted(){
    //this.popupItem = this.$el;
  },
  methods: {
    hideMenu(){
      this.isMenu = false;
    },
    setCategoryData(cat){
      console.log("setting category data");
      this.currentCategoryData = cat;
    },
    goTo(path){
      this.$router.push("/category/"+path);
      this.isMenu = false;
    }
  },
};
</script>
<style scoped>
.mega-menu{
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.17);
  padding: 20px;
  width: 100%;
  position: fixed;
  top: 80px;
  left: 50%;
  z-index: 100;
  padding: 20px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%);
}

.mega-menu:before {
    content: '';
    position: absolute;
    border: 11px solid transparent;
    border-bottom-color: #fff;
    top: -20px;
    left: 200px;
}

.mega-link{
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 20px;
}
.mega-link:hover{
  background-color: #e8ebee;
  color: #000; /*#ff5a5f*/;
}

.mega-link-text{
 line-height: 1.2;
}

.mega-sublinks{
  color: #6d767e;
  cursor: pointer;
  text-decoration: none;
}

.mega-sublinks:hover{
  color: #7cbc4b /*#2e3135*/;
}

.group-names{
  color: #4a4a4a;
  font-size: 1.2rem;
  margin-bottom: 20px;
  width: 100%;
  line-height: 1.2;
}

/*height brakepoints*/
@media screen and (max-height: 800px) {
  .mega-menu{
    overflow-y: scroll;
    max-height: 700px;
  }
  .mega-link{
    border-radius: 5px;
    padding: 5px 10px;
  }
  .mega-link-text{
    line-height: 1;
    font-size: 14px;
  }
  .mega-sublinks{
    font-size: 12px;
  }
  .group-names{
    font-size: 14px;
  }
}
</style>
