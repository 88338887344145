export default {
  data() {
    return {
      monthNames:{
        en: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        ro: ["Ianuarie", "Februarie", "Martie", "Aprilie", "Mai", "Iunie", "Iulie", "August", "Septembrie", "Octombrie", "Noiembrie", "Decembrie"],
        ru: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",  "Июль", "Август", "Сентябрь", "Октябрь",  "Ноябрь", "Декабрь"],
      },
    };
  },
  methods: {
    formatRegistered(dateStr, lang) {
      if(!dateStr) return;
      if(!lang) lang = "en";
      let date = new Date(dateStr);
      let formattedDate = this.monthNames[lang][date.getMonth()] + " " + date.getFullYear();
      return formattedDate;
    },
    formatDate(dateStr, lang){
      if(!dateStr) return;
      if(!lang) lang = "en";
      let date = new Date(dateStr);
      let formattedDate = this.monthNames[lang][date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
      return formattedDate;
    },
    formatDateTime(dateStr, lang){
      if(!dateStr) return;
      if(!lang) lang = "en";
      let date = new Date(dateStr);
      let formattedDate = date.getHours()+":"+date.getMinutes()+" "+this.monthNames[lang][date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
      return formattedDate;
    },
    formatTime(dateStr){    
      let date = new Date(dateStr);
      let formattedDate = date.getHours()+":"+date.getMinutes();
      return formattedDate;
    },
  }
};
