<template>
  <BottomNavComponent
    :swiper-color="colors[selected]"
    :icon-color="colors[selected]"
    :options="options"
    v-model="selected"
  />
</template>

<script>
import BottomNavComponent from "@/components/navigation/BottomNavComponent.vue";
import { mapState } from "vuex";

export default {
  name: "BottomNav",
  components: { BottomNavComponent },
  computed: {
    ...mapState(["lang"]),
    // options: () => {
    //   return [
    //     {
    //       id: 1,
    //       icon: "syntho house",
    //       title: this.$t("Home"),
    //       // path: {path: "/"},
    //     },
    //     {
    //       id: 2,
    //       icon: "syntho bars",
    //       title: this.$t("Categories"),
    //     },
    //     {
    //       id: 3,
    //       icon: "syntho circle-plus",
    //       title: this.$t("Add Advert"),
    //       // path: {path: "/add-advert"}
    //     },
    //     {
    //       id: 4,
    //       icon: "syntho user",
    //       title: this.$t("Account"),
    //       // path: {path: "/my-dashboard"},
    //     },
    //     {
    //       id: 5,
    //       icon: "syntho comments",
    //       title: this.$t("Messages"),
    //     },
    //   ];
    // },
  },
  data() {
    return {
    selected: 1,
    lastSelected: 1,
    options: [
        {
          id: 1,
          icon: "syntho house",
          title: "Home",
          // path: {path: "/"},
        },
        {
          id: 2,
          icon: "syntho bars",
          title: "Categories",
        },
        {
          id: 3,
          icon: "syntho circle-plus",
          title: "Add Advert",
          // path: {path: "/add-advert"}
        },
        {
          id: 4,
          icon: "syntho user",
          title: "Account",
          // path: {path: "/my-dashboard"},
        },
        {
          id: 5,
          icon: "syntho comments",
          title: "Chat",
        },
      ],
    colors: ["#FFB000", "#FF008D", "#FFB000", "#00C793", "#6E4FD7", "#FF0000"],
    };
  },
  mounted() {
    this.$root.$on("didCloseCatSidebar", () => {
      this.setLastSelected();
    });
    this.$root.$on("didSelectCatSidebar", () => {
      this.selected = 0;
    });
    this.$root.$on("didCloseAccSidebar", () => {
      this.setLastSelected();
    });
    this.$root.$on("didSelectAccSidebar", () => {
      this.selected = 0;
    });
  },
  methods: {
    onSwipeBottomNavigationClick(item) {
      if(item == 1){
        this.gotoPage("/");
      }
      if (item == 2) {
        console.log("open categories");
        this.$store.commit("modals/showModal", "catsidebar");
      }
      if (item == 3) {
        console.log("open add advert");
        this.gotoPage("/add-advert");
      }
      if (item == 4) {
        console.log("open account");
        //this.gotoPage("/my-dashboard");
        this.$store.commit("modals/showModal", "accsidebar");
      }
      if (item == 5) {
        console.log("open messages");
        this.gotoPage("/my-messages");
      }
    },
    setLastSelected() {
      if(this.lastSelected != 2 && this.lastSelected != 4){
        this.selected = this.lastSelected;
      }
    },
    gotoPage(path) {
      //if not same page
      if (this.$route.path !== path) {
        this.$router.push(path).catch((err) => {
          console.log(err);
        });
      }
    },
  },
  watch: {
    selected: function (val, oldVal) {
      this.lastSelected = oldVal;
      console.log("selected mobile menu:", val);
      this.onSwipeBottomNavigationClick(val);
    },
    //if root is /, set selected to 1
    $route(to, from) {
      if (to.path == "/") {
        this.selected = 1;
      }
    },
  },
};
</script>
<style>

.sm-btn-icon .circle-plus{
  transition: all 0.3s ease-in-out;
}

.sm-btn-icon:not(.sm-btn-icon-active) .circle-plus{
  font-size: 30px;
}

</style>

