import axios from "axios";
let apiUrl = process.env.VUE_APP_API_URL;

export default{
  namespaced: true,
  state: {
    user: {
      isLogged: false,
      username: null,
      email: null,
      status: null,
      id: null,
    },
  },
  getters: {
    isUserEmail: (state) => {
      return state.user.email;
    }
  },
  mutations: {
    loginUser(state, payload) {
      state.user.isLogged = true;
      state.user.token = payload.jwt;
      state.user.username = payload.username;
      state.user.email = payload.email;
      state.user.status = payload.status;
      state.user.id = payload.id;
      //local
      localStorage.sxToken = payload.jwt;
      localStorage.sxemail = payload.email;
    },
    logoutUser(state) {
      state.user.isLogged = false;
      state.user.token = null;
      state.user.username = null;
      state.user.email = null;
      state.user.status = null;
      state.user.id = null;
      //local
      localStorage.removeItem("sxToken");
      localStorage.removeItem("sxEmail");
    },
    validateUser(state, payload) {
      state.user.isLogged = true;
      state.user.token = localStorage.sxToken;
      state.user.username = payload.username;
      state.user.email = payload.email;
      state.user.status = payload.status;
      state.user.id = payload.id;
    },
    setStatus(state, payload) {
      localStorage.removeItem("sxToken");
      localStorage.sxToken = payload.jwt;
      state.user.token = payload.jwt
      state.user.status = payload.status;
    },
    clearUserData(state) {
      state.user.isLogged = false;
      state.user.token = null;
      state.user.username = null;
      state.user.email = null;
      state.user.status = null;
      state.user.id = null;
    },
  },
  actions: {
    loginUser({commit,dispatch}, payload) {
      commit("loginUser", payload);
      dispatch("favorites/loadFavorites","", { root: true });
      //commit('gridtabs/clearAll', null, { root: true });
     //commit('layouts/dropAllData', null, { root: true });
    },
    logoutUser({commit}) {
      commit("logoutUser");
      //commit('layouts/dropAllData', null, { root: true });
    },
  },
  modules: {
  }
}
