import { render, staticRenderFns } from "./CategoriesSidebar.vue?vue&type=template&id=d4084790&scoped=true&"
import script from "./CategoriesSidebar.vue?vue&type=script&lang=js&"
export * from "./CategoriesSidebar.vue?vue&type=script&lang=js&"
import style0 from "./CategoriesSidebar.vue?vue&type=style&index=0&id=d4084790&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4084790",
  null
  
)

export default component.exports